<form [formGroup]="clauseForm" class="viewer">
    <div class="edit-header">
        <app-ctbox-validate-name [parentGroup]="clauseForm" [controlName]="'name'"></app-ctbox-validate-name>
        <div class="edit-error">
            <mat-error *ngIf="clauseForm.controls.description.invalid">
                <ng-container i18n="@@ValidacionMensajeContenido">Debes introducir texto en el editor</ng-container>
                <span class="viewer-error-sub"> * </span>
            </mat-error>
        </div>
    </div>
    <div class="edit-body">
        <app-wysiwyg-editor-basic class="wysiwyg" [customConfig]="'cmcEditor'" [initialHeight]="300" [dataEditor]="selectedHtml" (changeContent)="changeContent($event)"></app-wysiwyg-editor-basic>
        <input matInput name="description" #description formControlName="description" type="text" hidden>
    </div>
</form>
