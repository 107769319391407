import { AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import {
    TemplateDocumentDTO, TemplateDocumentService, TemplateService, DocumentService, TemplateDTO, DocumentDTO,
    UserPermissionsOnDocumentDTO
} from 'src/app/api';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { ITemplateDocumentRevisionService } from 'src/app/core/shared/services/templates/template-document-revision/template-document-revision.service.interface';
import { WysiwygEditorStandardTrackComponent } from 'src/app/shared/components/wysiwyg-editor/wysiwyg-editor-standard-track/wysiwyg-editor-standard-track.component';
import { EditorDocumentParts } from '../wysiwyg-editor/models/editor-document-parts';

@Component({
    selector: 'app-template-revision-preview',
    templateUrl: './template-revision-preview.component.html'

})
export class TemplateRevisionPreviewComponent implements OnInit, AfterViewChecked {

    @ViewChild('documentEditor') documentEditor: WysiwygEditorStandardTrackComponent;

    @Input() documentId: string;
    @Input() templateDocumentId: string;
    @Input() fromTemplateDocument = false;
    @Input() isDocument = false;
    @Input() pendingDocuments: number;

    public get documentTypeCodes(): typeof DocumentTypeCodes {
        return DocumentTypeCodes;
    }

    public isReviewDisabledSubscription = new BehaviorSubject<boolean>(true);
    public lastOpenedDialog: MatDialogRef<any>;

    public templateDocument: TemplateDocumentDTO;
    public totalDocuments: number;
    public indexDocument: number;
    public templateName = '';
    public templateDocumentName: string;
    public templateDocumentType: string;
    public documentModified = '';
    public documentPermissions: UserPermissionsOnDocumentDTO;

    public templateNewVersion = '';
    public templateOldVersion = '';
    public isEditorReady = false;
    public infoTooltip: string = $localize`:@@ComparativaCambiosPlantillaActualizada: Comparativa de cambios realizados entre la última versión de la plantilla revisada y la actual`;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public genericDialogService: GenericDialogService,
                private apiTemplateDocument: TemplateDocumentService,
                private apiTemplate: TemplateService,
                private apiDocument: DocumentService,
                private templateDocumentRevisionService: ITemplateDocumentRevisionService,
                private cdRef: ChangeDetectorRef) {
        this.totalDocuments = templateDocumentRevisionService.getCurrentStateDocuments().size;
        this.indexDocument = 0;
        this.documentId = data.dataSourceComponent;
        this.templateDocumentId = data.templateDocumentId;
        this.fromTemplateDocument = data.fromTemplateDocument;
    }

    public ngOnInit(): void {
        if (this.fromTemplateDocument) {
            this.getPendingDocumentFromTemplate(this.templateDocumentId);
            return;
        }
        this.getPendingDocument(this.documentId);
    }

    public ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    public editorReady() {
        this.isEditorReady = true;
        if (this.templateDocumentId) {
            this.documentModified = this.templateDocument?.document?.html;
            this.documentPermissions = this.templateDocument?.document?.userPermissions;
            this.templateDocumentName = this.templateDocument?.document.name;
            this.templateDocumentType = this.getTemplateDocumentType(this.templateDocument?.document.documentTypes.code);
        } else {
            this.getPendingDocument(this.documentId);
        }
    }

    public getTemplateDocumentType(code: any): string {
        if (!code) {
            this.templateDocumentType = '';
            return this.templateDocumentType;
        }
        switch (code) {
            case 1:
                this.templateDocumentType = $localize`:@@DocumentoActualRevisionPlantillaRevisionTitulo: Documento afectado: `;
                break;
            case 2:
                this.templateDocumentType = $localize`:@@DocumentoActualRevisionPlantillaRevisionTitulo: Documento afectado: `;
                break;
            case 3:
                this.templateDocumentType = $localize`:@@DocumentoActuaRevisionlPlantillaRevisionTitulo: Plantilla afectada: `;
                break;
            default:
                this.templateDocumentType = code;
        }
        return this.templateDocumentType;
    }

    public getPendingDocumentFromTemplate(templateDocumentId: string) {
        this.loadDocument(templateDocumentId);
    }

    public getPendingDocument(documentId: string) {
        this.apiTemplateDocument.templateDocumentSearchPendingDocumentDocumentIdGet(documentId)
            .subscribe((pendingDocuments: TemplateDocumentDTO) => {
                const stateDocument = new Map<string, string>();
                this.templateDocumentRevisionService.UpdateStateDocument(stateDocument);
                this.templateDocumentRevisionService.setPending(pendingDocuments);
                this.loadDocument(pendingDocuments.id);

                this.pendingDocuments = this.templateDocumentRevisionService.getNumPendingDocuments();
            });
    }

    public loadDocument(templateDocumentId: string) {
        this.apiTemplateDocument.templateDocumentIdGet(templateDocumentId).subscribe((templateDocument: TemplateDocumentDTO) => {
            this.templateDocument = templateDocument;
            this.updateTemplateContent();
            this.indexDocument = this.templateDocumentRevisionService.getIndexDocumentTemplate(templateDocument);
            this.documentModified = templateDocument?.document?.html;
            this.documentPermissions = this.templateDocument?.document?.userPermissions;
            this.templateName = templateDocument?.template.name;
            this.totalDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments().size;
            this.templateDocumentName = this.templateDocument?.document.name;
            this.templateDocumentType = this.getTemplateDocumentType(this.templateDocument?.document.documentTypes.code);
            setTimeout(() => {
                this.updateTemplateContent();
            });
        });
    }

    public checkPendingDocuments(templateId: string) {
        if (this.totalDocuments > 0) {
            return;
        }
        this.templateDocumentRevisionService.loadPendingCBDocumentsForTemplate(templateId);
    }

    public openLeaveConfirmationMessage(): void {
        if (this.checkChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    if (this.lastOpenedDialog) {
                        this.lastOpenedDialog.close();
                    } else {
                        return;
                    }
                }
            });
        } else {
            if (this.lastOpenedDialog) {
                this.lastOpenedDialog.close();
            } else {
                return;
            }
        }

    }

    public updateTemplateContent(): void {
        this.templateNewVersion = this.templateDocument ? this.templateDocument.template.html : '';
        this.templateOldVersion = this.templateDocument ? this.templateDocument.templateVersionHtml : '';
    }

    public navegateNextDocument(): void {
        if (this.checkChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    const currentNextTemplateId = this.templateDocumentRevisionService.getNextDocumentTemplateId(this.indexDocument);
                    this.loadDocument(currentNextTemplateId);
                }
            });
            return;
        }
        const nextTemplateId = this.templateDocumentRevisionService.getNextDocumentTemplateId(this.indexDocument);
        this.loadDocument(nextTemplateId);
    }

    public navegatePreviousDocument(): void {
        if (this.checkChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    const currentPreviousTemplateId =
                        this.templateDocumentRevisionService.getPreviousDocumentTemplateId(this.indexDocument);
                    this.loadDocument(currentPreviousTemplateId);
                }
            });
            return;
        }
        const previousTemplateId = this.templateDocumentRevisionService.getPreviousDocumentTemplateId(this.indexDocument);
        this.loadDocument(previousTemplateId);
    }

    public navegateFirstDocument(): void {
        if (this.checkChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    const currentNextTemplateId = this.templateDocumentRevisionService.getFirstDocumentTemplateId(this.indexDocument);
                    this.loadDocument(currentNextTemplateId);
                }
            });
            return;
        }
        const nextTemplateId = this.templateDocumentRevisionService.getFirstDocumentTemplateId(this.indexDocument);
        this.loadDocument(nextTemplateId);
    }

    public navegateLastDocument(): void {
        if (this.checkChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    const currentLastTemplateId = this.templateDocumentRevisionService.getLastDocumentTemplateId(this.indexDocument);
                    this.loadDocument(currentLastTemplateId);
                }
            });
            return;
        }
        const nextTemplateId = this.templateDocumentRevisionService.getLastDocumentTemplateId(this.indexDocument);
        this.loadDocument(nextTemplateId);
    }

    public checkChanges(): boolean {
        if (!this.isPending(this.templateDocument)) {
            return false;
        }
        return this.hasUnsavedChanges();
    }

    public save(): void {
        if (this.templateDocument.document?.documentTypes?.code === DocumentTypeCodes.Template) {
            this.saveAsTemplate();
        } else {
            this.saveAsDocument();
        }
    }

    public acceptChanges(): void {
        const message = $localize`:@@ConfirmacionCambioMensaje:Vas a realizar cambios en el documento. ¿Estás seguro?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result === true) {
                if (this.templateDocument.document?.documentTypes?.code === DocumentTypeCodes.Template) {
                    this.saveAndReviewAsTemplate();
                } else {
                    this.saveAndReviewAsDocument();
                }
            }
        });
    }

    public saveAsTemplate(): void {
        this.apiTemplate.templatesIdGet(this.templateDocument.documentId).subscribe((template: TemplateDTO) => {
            template.html = this.documentModified;
            this.apiTemplate.templatesPut(template).subscribe(() => {
                this.resetUnsavedChanges();
                const message = $localize`:@@EditarPlantillaGuardarMensaje:La plantilla ha sido modificada.`;
                this.genericDialogService.showMessage(message);
            }, () => {
                const message = $localize`:@@NuevaPlantillaErrorAlGuardarMensaje:Se ha producido un error al guardar la plantilla.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public saveAsDocument(): void {
        this.apiDocument.documentIdGet(this.templateDocument.documentId).subscribe((document: DocumentDTO) => {
            document.html = this.documentModified;
            this.apiDocument.documentPut(document).subscribe(() => {
                this.resetUnsavedChanges();
                const message = $localize`:@@EditarContratoGuardarMensaje:El documento ha sido modificado.`;
                this.genericDialogService.showMessage(message);
            }, () => {
                const message = $localize`:@@NuevaContratoErrorAlGuardarMensaje:Se ha producido un error al guardar el documento.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public saveAndReviewAsTemplate(): void {
        this.apiTemplate.templatesIdGet(this.templateDocument.documentId).subscribe((template: TemplateDTO) => {
            template.html = this.documentModified;
            this.apiTemplate.templatesSaveAndReviewedPut(template).subscribe(() => {
                this.doAfterReview();
            }, () => {
                const message = $localize`:@@NuevaContratoErrorAlGuardarMensaje:Se ha producido un error al guardar el documento.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public saveAndReviewAsDocument(): void {
        this.apiDocument.documentIdGet(this.templateDocument.documentId).subscribe((document: DocumentDTO) => {
            document.html = this.documentModified;
            this.apiDocument.documentSaveAndReviewedPut(document).subscribe(() => {
                this.doAfterReview();
            }, () => {
                const message = $localize`:@@NuevaContratoErrorAlGuardarMensaje:Se ha producido un error al guardar el documento.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public doAfterReview(): void {
        this.resetUnsavedChanges();
        this.templateDocumentRevisionService.setApplied(this.templateDocument);
        this.pendingDocuments = this.templateDocumentRevisionService.getNumPendingDocuments();
        this.loadDocument(this.templateDocument.id);
    }

    public isPending(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isPending(document);
    }

    public isApplied(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isApplied(document);
    }

    public isKeep(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isKeep(document);
    }

    public resetUnsavedChanges(): void {
        this.documentEditor.resetUnsavedChanges();
    }

    public hasUnsavedChanges(): boolean {
        return this.documentEditor?.hasUnsavedChanges();
    }

    public modifiedHtml(editorDocumentParts: EditorDocumentParts): void {
        this.documentModified = editorDocumentParts?.html;
    }

    public showComparer(): boolean {
        return this.templateNewVersion && this.templateNewVersion.trim() !== '' &&
            this.templateOldVersion && this.templateOldVersion.trim() !== '';
    }

}
