<div class="wysiwyg" #mainContainer>
    <div class="wysiwyg-editor" [ngClass]="{' 01 ' : hideColumnComments() && showComments && !hideCommentsColumn, ' 02 ' : !hideColumnComments() &&(!showComments || hideCommentsColumn), '03 ' : hideColumnComments()}">
        <ckeditor class="wysiwyg-ckeditor" *ngIf="this.showEditor()" #editor  (blur)="sendDataChangeWithoutDelay($event)" (change)="sendDataChange($event)" (ready)="onEditorReady($event)" (dataReady)="dataReady($event)" [(data)]="dataEditor"></ckeditor>
        <app-scroll-to-top scrollTop="top" *ngIf="showButtonTop"></app-scroll-to-top>
    </div>
    <div class="wysiwyg-comments" [ngClass]="{'wysiwyg-comments-hidden': hideColumnComments(),   ' 01 ' : showComments && !hideCommentsColumn, ' 02 ' : !showComments || hideCommentsColumn }">
        <div class="comments">
            <div class="comments-top">
                <div class="comments-title" i18n="@@ComentariosTitulo">Comentarios</div>
                <div class="comments-button" *ngIf="canDeleteAllComments()">
                    <ctbox-button buttonType="secondary" buttonIcon="delete" buttonText="Limpiar todos los comentarios" i18n-buttonText="@@LimpiarComentarios" (buttonClick)="deleteAllComments()"></ctbox-button>
                </div>
                <div class="comments-text" *ngIf="!hasComments && showTextComments && (!hideAllButtons || this.showComments)">
                    Si quieres añadir un comentario, selecciona el texto y usa el icono <span class="cke_button_icon cke_button__annotate_icon">&nbsp;</span> del editor
                </div>
            </div>
            <div class="comments-container" #comments></div>
        </div>
    </div>
</div>

<button #openModalButton hidden (click)="openCreateClause()" type="button">click</button>
<button #insertClauseFromLibraryButton hidden (click)="isAbleToInsertClauseLibrary() ? openInsertClauseFromLibrary() : showMessage(textSelectionInsertMessage)" type="button">click</button>
<button #insertClauseCmc hidden (click)="openInsertClauseCMC()" type="button">click</button>
<button #insertSignatureButton hidden (click)="openInsertSignature()" type="button">click</button>
<button #editSignatureButton hidden (click)="openEditSignature()" type="button">click</button>
<button #previousChangeFliteButton hidden (click)="goToPreviousChange()" type="button" i18n="@@PrevioCambio">Cambio anterior</button>
<button #nextChangeFliteButton hidden (click)="goToNextChange()" type="button" i18n="@@SiguienteCambio">Siguiente cambio</button>
<button #insertClauseFromTreeButton hidden (click)="isAbleToInsertClauseTreeLibrary() ? openInsertClauseFromTreeLibrary() : showMessage(textSelectionInsertMessage)" type="button">click</button>
