import { Component, EventEmitter, HostBinding, OnInit, Output, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { UserPermissionsOnClauseDTO, UserPermissionsOnDocumentDTO } from 'src/app/api';
import { MasterData } from 'src/app/api/model/masterData';
import { TypeUserService } from 'src/app/core/shared/services/type-user/type-user.service';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { getInvalidCharacters } from 'src/app/core/shared/validators/invalidCharactersValidator';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';

@Component({
    selector: 'app-ctbox-edit-name',
    templateUrl: './ctbox-edit-name.component.html',
    styleUrls: ['./ctbox-edit-name.component.scss'],
})
export class CtboxEditNameComponent implements OnInit {

    @ViewChild('nameInput') nameInput: ElementRef;
    @HostBinding('className') componentClass: string;

    @Input() hasButtonBack = true;
    @Input() canEditName = false;
    @Input() name: string;
    @Input() userPermissions: UserPermissionsOnDocumentDTO | UserPermissionsOnClauseDTO;
    @Input() documentType: MasterData;
    @Input() hasRelatedDocuments: boolean;
    @Input() maxNameLenght: number = 350;
    @Input() editionMode: boolean = false;

    @Output() nameChanged = new EventEmitter<string>();
    @Output() goBackClicked = new EventEmitter<void>();

    public get documentTypeCodes(): typeof DocumentTypeCodes {
        return DocumentTypeCodes;
    }

    public isEditMode = false;
    public originalName: string;
    public wrongName: string = null;

    public isAnnexed = false;
    public isPrincipal = false;

    constructor(public typeUserService: TypeUserService,
        public genericDialogService: GenericDialogService) {
        this.componentClass = 'ctbox-edit-name';
        this.isEditMode = this.editionMode;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.name) {
            this.isEditMode = false;
        }

        if (changes.editionMode) {
            this.isEditMode = changes.editionMode.currentValue;
            if (this.isEditMode) {
                setTimeout(()=>{
                    this.nameInput.nativeElement.focus();
                });
            }
        }
    }

    public ngOnInit(): void {
        this.isAnnexed = this.documentType?.code === this.documentTypeCodes.Annexed;
        this.isPrincipal = this.documentType?.code === this.documentTypeCodes.Contract && this.hasRelatedDocuments;
    }

    public startEdit() {
        this.originalName = this.name;

        this.isEditMode = true;

        setTimeout(()=>{
            this.nameInput.nativeElement.focus();
        }, 10);
    }

    public resetName() {
        this.name = this.originalName;
        this.nameInput.nativeElement.value = this.originalName;
    }

    public editMode(event: any) {
        if (this.isEditMode) {
            this.finishEdit(event);
            return;
        }

        this.startEdit();
    }

    public finishEdit(event: any) {
        event.stopPropagation();
        const newName = this.nameInput.nativeElement.value;
        if (!this.isValidName(newName, this.wrongName !== newName)) {
            this.wrongName = newName;
            return false;
        } else {
            this.wrongName = null;
        }

        this.nameChanged.emit(newName);
        this.isEditMode = false;
    }

    public goBack() {
        this.goBackClicked.emit();
    }

    private isValidName(name: string, showErrorMessage: boolean): boolean {
        if (!name) {
            if (showErrorMessage) {
                const message = $localize`:@@emptyNameError:Debes introducir un nombre`;
                this.genericDialogService.showMessage(message).afterClosed().subscribe(() => this.nameInput.nativeElement.focus());
            }
            return false;
        }

        if (name.length > this.maxNameLenght) {
            const message = $localize`:@@ValidacionMensajeTituloLongitud:La longitud del título no puede superar ${this.maxNameLenght}:INTERPOLATION: caracteres.`;
            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => this.nameInput.nativeElement.focus());
            return false;
        }

        const invalidCharacters = getInvalidCharacters(name);
        if (invalidCharacters) {
            if (showErrorMessage) {
                const message = $localize`:@@nameWithInvalidCharacters:El nombre no puede contener los siguientes caracteres inválidos: ` + ' ' + invalidCharacters;
                this.genericDialogService.showMessage(message).afterClosed().subscribe(() => this.nameInput.nativeElement.focus());
            }
            return false;
        }

        return true;
    }
}
