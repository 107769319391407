import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import {
    TemplateService, CBDocumentDTO, TemplateDTO, TemplateDownloadService, TemplateDownloadDTO,
    OriginTemplateVersionService, OriginTemplateVersionDTO, DocumentDTO, DocumentService, ErrorDocumentOperationDTO
} from 'src/app/api';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ITransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface';
import { ICkeditorFormsUtilsService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-utils.service.interface';
import { WysiwygEditorStandardTrackComponent } from 'src/app/shared/components/wysiwyg-editor/wysiwyg-editor-standard-track/wysiwyg-editor-standard-track.component';
import { HtmlComparerComponent } from '../html-comparer/html-comparer.component';
import { EditorDocumentParts } from '../wysiwyg-editor/models/editor-document-parts';

@Component({
    selector: 'app-template-lld-revision-preview',
    templateUrl: './template-lld-revision-preview.component.html'

})
export class TemplateLldRevisionPreviewComponent implements OnInit {

    @ViewChild('documentEditor') documentEditor: WysiwygEditorStandardTrackComponent;
    @ViewChild('templatesComparer') templatesComparer: HtmlComparerComponent;

    @Input() documentId: string;
    @Input() templateDocumentId: string;
    @Input() isDocumentATemplate = false;
    @Input() pendingDocuments = 1;

    public isReviewDisabledSubscription = new BehaviorSubject<boolean>(true);
    public lastOpenedDialog: MatDialogRef<any>;

    public forSignaturePreviously = false;
    public document: CBDocumentDTO;
    public totalDocuments = 1;
    public templateNewVersion = '';
    public templateOldVersion = '';
    public templateName = '';
    public templateDocumentName: string;
    public templateDocumentType: string;
    public templateCoText = '';
    public documentModified = '';

    public expirationDays: number;
    public expirationDate: Date;
    public newExpirationDate: Date;
    public existCo = false;
    public isEditorReady = false;
    public pendingChanges = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        public genericDialogService: GenericDialogService,
        private templateService: TemplateService,
        private documentService: DocumentService,
        private transformCleanHtmlService: ITransformCleanHtmlService,
        private templateDownloadService: TemplateDownloadService,
        private ckeditorFormsUtilsService: ICkeditorFormsUtilsService,
        private originTemplateVersionService: OriginTemplateVersionService
    ) {
        this.documentId = data.dataSourceComponent.id;
        this.templateDocumentId = data.dataSourceComponent.templateDocumentId;
        this.isDocumentATemplate = data.dataSourceComponent.isDocumentATemplate;
        this.editorReady();
    }

    public ngOnInit(): void {
        this.loadCBDocument(this.documentId);
    }

    public editorReady() {
        if (this.document) {
            this.documentModified = this.document.html;
            this.templateDocumentName = this.document.name;
            this.templateDocumentType = this.GetTemplateDocumentType(this.document?.documentTypes.code);
        } else {
            this.loadCBDocument(this.documentId);
        }
        this.isEditorReady = true;
    }

    public GetTemplateDocumentType(code: any): string {
        switch (code) {
            case 1:
                this.templateDocumentType = $localize`:@@DocumentoActualRevisionPlantillaRevisionTitulo: Documento afectado: `;
                break;
            case 2:
                this.templateDocumentType = $localize`:@@DocumentoActualRevisionPlantillaRevisionTitulo: Documento afectado: `;
                break;
            case 3:
                this.templateDocumentType = $localize`:@@DocumentoActuaRevisionlPlantillaRevisionTitulo: Plantilla afectada: `;
                break;
            default:
                this.templateDocumentType = code;
        }
        return this.templateDocumentType;
    }

    public getPendingDocument(document: CBDocumentDTO) {
        this.pendingDocuments = document.warningChanges.hasTemplateLLDPendingRevision ? 1 : 0;
    }

    public loadCBDocument(documentId: string) {
        if (this.isDocumentATemplate) {
            this.loadTemplate(documentId);
            return;
        }
        this.loadDocument(documentId);
    }

    public loadDocument(documentId: string) {
        this.documentService.documentIdGet(documentId).subscribe((document: CBDocumentDTO) => {
            this.updateDocumentTemplates(document);
        });
    }

    public loadTemplate(documentId: string) {
        this.templateService.templatesIdGet(documentId).subscribe((document: CBDocumentDTO) => {
            this.updateDocumentTemplates(document);
        });
    }

    public updateDocumentTemplates(document: CBDocumentDTO) {
        this.document = document;
        this.documentModified = document?.html;
        this.getPendingDocument(document);
        this.getTemplateNewVersion(document?.iddOrigin);
        this.getTemplateOldVersion(document?.originTemplateVersion?.id);
        this.templatesComparer?.setCleanDocumentAsComparerDocument();
        this.totalDocuments = 1;
        this.templateDocumentName = this.document.name;
        this.templateDocumentType = this.GetTemplateDocumentType(this.document?.documentTypes.code);
    }

    public getTemplateNewVersion(iddOrigin: string) {
        if (!iddOrigin) {
            this.templateCoText = '';
            this.templateName = '';
            this.templateNewVersion = '';
            this.checkExistCO();
            return;
        }
        this.templateDownloadService.templateDownloadsWithCoIddGet(iddOrigin).subscribe((templateDownload: TemplateDownloadDTO) => {
            this.templateCoText = templateDownload.co;
            this.checkExistCO();
            this.templateName = templateDownload.name;
            this.templateNewVersion = this.extractTemplateContent(templateDownload.html);
        }, () => {
            this.templateNewVersion = '';
        });
    }

    public checkExistCO() {
        this.existCo = this.templateCoText !== null && this.templateCoText.trim() !== '';
    }

    public getTemplateOldVersion(idOriginTemplateVersion: string) {
        if (!idOriginTemplateVersion) {
            this.templateOldVersion = '';
            return;
        }

        this.originTemplateVersionService.originTemplateVersionsTemplateLldIdGet(idOriginTemplateVersion).subscribe(
            (oldVersionTemplate: OriginTemplateVersionDTO) => {
                this.templateOldVersion = this.extractTemplateContent(oldVersionTemplate.html);
            });
    }

    public acceptChanges(): void {
        const message = $localize`:@@ConfirmacionCambioMensaje:Vas a realizar cambios en el documento. ¿Estás seguro?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result === 'true') {
                if (this.isDocumentATemplate) {
                    this.saveAndReviewAsTemplate();
                } else {
                    this.saveAndReviewAsDocument();
                }
            }
        });
    }

    private extractTemplateContent(html: string): string {
        const templateWithoutHeader = this.transformCleanHtmlService.cleanHeader(html);
        const domParser = new DOMParser();
        const documentWithoutHeader = domParser.parseFromString(templateWithoutHeader, 'text/html');
        const DO_NOT_REPLACE_IN_RAW = false;
        this.transformCleanHtmlService.moveValuesTextService(documentWithoutHeader, DO_NOT_REPLACE_IN_RAW);
        this.ckeditorFormsUtilsService.configureInputsAndSelectedForDocumentInVisor(documentWithoutHeader);
        const htmlElement = documentWithoutHeader.querySelector('html');
        return htmlElement.outerHTML;
    }

    public tryClose(): void {
        if (this.hasUnsavedChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result !== true) {
                    return;
                }
            });
        }
    }

    public save(): void {
        if (this.isDocumentATemplate) {
            this.saveAsTemplate();
        } else {
            this.saveAsDocument();
        }
    }

    public saveAsTemplate(): void {
        this.templateService.templatesIdGet(this.document.id).subscribe((template: TemplateDTO) => {
            template.html = this.documentModified;
            this.templateService.templatesPut(template).subscribe(() => {
                this.resetUnsavedChanges();
                const message = $localize`:@@EditarPlantillaGuardarMensaje:La plantilla ha sido modificada.`;
                this.genericDialogService.showMessage(message);
            }, () => {
                const message = $localize`:@@NuevaPlantillaErrorAlGuardarMensaje:Se ha producido un error al guardar la plantilla.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public saveAsDocument(): void {
        this.documentService.documentIdGet(this.document.id).subscribe((document: DocumentDTO) => {
            document.html = this.documentModified;
            this.documentService.documentPut(document).subscribe(() => {
                this.resetUnsavedChanges();
                const message = $localize`:@@EditarContratoGuardarMensaje:El documento ha sido modificado.`;
                this.genericDialogService.showMessage(message);
            }, (error: any) => {
                this.manageErrors(error);
            });
        });
    }

    public saveAndReviewAsTemplate(): void {
        this.templateService.templatesIdGet(this.document.id).subscribe((template: TemplateDTO) => {
            template.html = this.documentModified;
            this.templateService.templatesSaveAndReviewedLldPendingPut(template).subscribe(() => {
                this.doAfterReview();
            }, () => {
                const message = $localize`:@@NuevaContratoErrorAlGuardarMensaje:Se ha producido un error al guardar el documento.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public showComparer(): boolean {
        return this.templateNewVersion && this.templateNewVersion.trim() !== '' &&
            this.templateOldVersion && this.templateOldVersion.trim() !== '';
    }

    public saveAndReviewAsDocument(): void {
        this.documentService.documentIdGet(this.document.id).subscribe((document: DocumentDTO) => {
            document.html = this.documentModified;
            this.documentService.documentSaveAndReviewedLldPendingPut(document).subscribe(() => {
                this.doAfterReview();
            }, (error: any) => {
                this.manageErrors(error);
            });
        });
    }

    public doAfterReview(): void {
        this.resetUnsavedChanges();
        this.pendingDocuments = 0;
        this.loadCBDocument(this.document.id);
    }

    public documentChanged(text: string) {
        this.documentModified = text;
    }

    public resetUnsavedChanges(): void {
        this.documentEditor.resetUnsavedChanges();
    }

    public hasUnsavedChanges(): boolean {
        return this.documentEditor?.hasUnsavedChanges();
    }

    public modifiedHtml(editorDocumentParts: EditorDocumentParts): void {
        this.documentModified = editorDocumentParts?.html;
    }

    private manageErrors(error: any) {
        this.documentService.documentErrorDescriptionGet().subscribe((errorDescriptions: ErrorDocumentOperationDTO) => {
            let message = $localize`:@@NuevaContratoErrorAlCargar:Se ha producido un error al cargar el documento. Inténtalo de nuevo en unos instantes.`;
            if (error.error?.Document && error.error.Document.length > 0) {
                const errorDescription = error.error.Document[0];
                if (errorDescription === errorDescriptions.userHasNoPermissionsForOperationMessage) {
                    message = $localize`:@@NuevoContratoModalErrorSinPermisosGuardarMensaje:No tienes permisos para realizar esta operación.`;
                } else if (errorDescription === errorDescriptions.userHasNoPermissionInFolderMessage) {
                    message = $localize`:@@NuevoContratoModalErrorSinPermisosCarpetaGuardarMensaje:Te han retirado los permisos en la carpeta. Para cualquier aclaración, ponte en contacto con alguno de tus administradores.`;
                }
            }
            this.genericDialogService.showMessage(message);
        }, () => {
            const message = $localize`:@@ContratoErrorAlOperarMensaje:Se ha producido un error al realizar la operación en el documento.`;
            this.genericDialogService.showMessage(message);
        });
    }
}
