import { Component, OnInit, Input, OnDestroy, EventEmitter } from '@angular/core';
import { ClauseDTO, TableOfContentsDTO } from 'src/app/api';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IClausesFolderService } from 'src/app/core/shared/services/clauses/clauses-folder/clauses-folder.service.interface';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { IClauseCreationService } from 'src/app/core/standard/services/clauses/clause-creation/clause-creation.service.interface';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { EditorDocumentParts } from '../models/editor-document-parts';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-new-clause-modal',
    templateUrl: './new-clause-modal.component.html',
    styleUrls: ['./new-clause-modal.component.scss']
})
export class NewClauseModalComponent implements OnInit, OnDestroy {

    @Input() selectedHtml: string;
    @Input() cssClassName: string;

    public clauseToEdit: ClauseDTO = {
        id: '',
        name: '',
        description: ''
    };

    public clauseForm: UntypedFormGroup;
    public lastOpenedDialog: MatDialogRef<any>;

    public isValid = new BehaviorSubject<boolean>(false);
    public afterSaveObserver = new BehaviorSubject<boolean>(false);
    public doSaveObserver = new BehaviorSubject<boolean>(false);

    public availableContents: TableOfContentsDTO[];
    public currentTableOfcontents: TableOfContentsDTO = {};

    private currentFolderId: string;
    private MAX_LENGTH = 350;

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        public genericDialogService: GenericDialogService,
        private clausesFolderService: IClausesFolderService,
        private clauseCreationService: IClauseCreationService
    ) { }

    public ngOnInit(): void {
        this.initFormModel();

        this.doSaveObserver.pipe(takeUntil(this.onDestroy$))
            .subscribe((doSave: boolean) => {
                if (!doSave) {
                    return;
                }
                this.saveClause();
            });

        this.clausesFolderService.getCurrentFolderId()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(folderId => this.currentFolderId = folderId);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public saveClause(): void {
        const clause: ClauseDTO = {
            name: this.clauseForm.controls.name.value,
            description: this.clauseForm.controls.description.value,
            html: this.clauseForm.controls.description.value,
        };

        this.clauseToEdit = clause;

        this.lastOpenedDialog = this.clauseCreationService.saveOpenFolder(this.clauseToEdit);
        this.lastOpenedDialog.afterClosed().subscribe((result: boolean) => {
            if (!result) {
                return;
            }
            this.clauseToEdit.clauseFolderId = this.currentFolderId;
            this.clauseCreationService.afterClosed(result, this.clauseToEdit).then((clauseSaved: ClauseDTO) => {
                if (!clauseSaved) {
                    return;
                }

                this.doSavedSuccesful(clauseSaved);
            });
        });
    }

    public changeContent(editorDocumentParts: EditorDocumentParts): void {
        this.clauseForm.controls.description.setValue(editorDocumentParts?.html);
        this.clauseForm.controls.description.updateValueAndValidity();
    }

    private doSavedSuccesful(clauseSaved: ClauseDTO): void {
        const folderName = this.clausesFolderService.getCurrentFolderName();
        const message = $localize`:@@NuevaClausulaDesdeEditorGuardarMensaje:La cláusula creada se guardará en la carpeta: ` + folderName;
        this.genericDialogService.showMessage(message).afterClosed().subscribe((resultFolder) => {
            if (resultFolder !== true) {
                return;
            }
            this.updateCurrentClause();
            this.clauseToEdit.id = clauseSaved.id;
            this.afterSaveObserver.next(true);
        });

    }

    private initFormModel(): void {
        this.clauseForm = new UntypedFormGroup(
            {
                name: new UntypedFormControl(
                    null, [Validators.required, Validators.maxLength(this.MAX_LENGTH)]),
                description: new UntypedFormControl(
                    null, [Validators.required]),
            }
        );

        this.clauseForm.statusChanges
            .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
            .subscribe(() => {
                this.sendIsValidState();
                this.updateCurrentClause();
            });
    }

    private sendIsValidState(): void {
        this.isValid.next(this.clauseForm.valid);
    }

    private updateCurrentClause(): void {
        this.clauseToEdit.name = this.clauseForm.controls.name.value;
        this.clauseToEdit.description = this.clauseForm.controls.description.value;
    }
}
