import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Observable } from 'rxjs';

@Injectable()
export class LegalAlertsService {

    private basePath = '/api/manager-management/alerts';

    constructor(private readonly apiProcessorService: ApiProcessorService) { }

    public getAll(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.basePath }`;

        return this.apiProcessorService.proccessRequest(apiRequest)
    }

    public get(idAlert: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.basePath }/${ idAlert }`;

        return this.apiProcessorService.proccessRequest(apiRequest)
    }

    public delete(ids: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = JSON.stringify(ids);
        apiRequest.endpointUrl = `${ this.basePath }/bulk-delete`;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getEmail(id: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.basePath }/email/${ id }`;
        apiRequest.responseType = 'text';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
