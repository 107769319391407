import { EditorDocumentParts } from './../models/editor-document-parts';
import {
    Component, OnInit, Input, ViewChild, ElementRef, EventEmitter,
    Output, ChangeDetectorRef, SimpleChanges, OnChanges, AfterViewChecked, OnDestroy, ChangeDetectionStrategy, AfterViewInit
} from '@angular/core';
import { CKEditor4 } from 'ckeditor4-angular';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable } from 'rxjs';
import { PathLocationStrategy } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ClauseDTO, UserDTO, UserInfoDTO } from 'src/app/api';
import { UserPermissionsOnDocumentDTO } from 'src/app/api/model/userPermissionsOnDocumentDTO';
import { TypeUserService } from 'src/app/core/shared/services/type-user/type-user.service';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { GenericDialogConfig } from 'src/app/core/shared/models/generic-dialog-config.model';
import { SignatureConfigurationComponent } from 'src/app/shared/components/signature-configuration/signature-configuration.component';
import { SignatureModel } from 'src/app/shared/components/signature-configuration/models/signature-model';
import { dialogTypes } from 'src/app/shared/components/ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';
import { CkeditorFormsTextFieldService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-text-field.service';
import { CkeditorFormsCheckBoxService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-checkbox.service';
import { CkeditorFormsRadioService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-radio.service';
import { CkeditorFormsTextAreaService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-text-area.service';
import { CkeditorFormsSelectService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-select.service';
import { CkeditorPluginFindService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-plugin-find.service';
import { CkeditorPluginLinkService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-plugin-link';
import { CkeditorPluginTableService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-plugin-table';
import { CkeditorFontsService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-fonts';
import { CkeditorFormatsService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-formats';
import { CkeditorStylesCMCService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-styles-cmc';
import { ICkeditorFormsUtilsService } from 'src/app/shared/components/wysiwyg-editor/services/ckeditor-forms-utils.service.interface';
import { IShowHideElementsService } from 'src/app/shared/components/wysiwyg-editor/services/show-hide-elements.service.interface';
import { NewClauseModalComponent } from 'src/app/shared/components/wysiwyg-editor/new-clause-modal/new-clause-modal.component';
import { ListClauseModalComponent } from 'src/app/shared/components/wysiwyg-editor/list-clause-modal/list-clause-modal.component';
import { ClausesTreeTextComponent } from 'src/app/pages/cmc/clauses-library/clauses-tree-text/clauses-tree-text.component';
import { UserPlugin } from './user-plugin';
import { permissionsEditDeleteConfiguration } from './permissionsConfig';
import { CkeditorReadOnlyClauseWidgetService } from '../services/ckeditor-read-only-clause-widget.service';
import { takeUntil } from 'rxjs/operators';
import { IHtmlEventsService } from 'src/app/core/shared/services/html-events/html-events.service.interface';
import { TransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service';
import { CkeditorPluginHelpNotesService } from '../services/ckeditor-plugin-help-notes.service';
import { CkeditorPluginTableToolsService } from '../services/ckeditor-plugin-table-tools.service';

declare let CKEDITOR: any;
declare let window: any;

@Component({
    selector: 'app-wysiwyg-editor-standard-track',
    templateUrl: './wysiwyg-editor-standard-track.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WysiwygEditorStandardTrackComponent implements AfterViewChecked, OnDestroy, OnInit, OnChanges, AfterViewInit {

    @ViewChild('openModalButton') openModalButton: ElementRef;
    @ViewChild('insertClauseFromLibraryButton') insertClauseFromLibraryButton: ElementRef;
    @ViewChild('insertClauseFromTreeButton') insertClauseFromTreeButton: ElementRef;
    @ViewChild('insertClauseCmc', { static: true }) insertClauseCmc: ElementRef;
    @ViewChild('insertSignatureButton') insertSignatureButton: ElementRef;
    @ViewChild('comments') comments: ElementRef;
    @ViewChild('nextChangeFliteButton') nextChangeFliteButton: ElementRef;
    @ViewChild('previousChangeFliteButton') previousChangeFliteButton: ElementRef;
    @ViewChild('editSignatureButton') editSignatureButton: ElementRef;

    @Input() set customConfig(value: string) {
        this.isCmcConfig = value === 'cmcEditor';
    }

    @Input() hideAllButtons: boolean;
    @Input() hideCommentsColumn: boolean;
    @Input() showAddNewClause = true;
    @Input() showAddNewClauseTree = false;
    @Input() showSignatureButton = true;
    @Input() showTrackChanges: boolean;
    @Input() showButDisableTrackChanges: boolean;
    @Input() showComments: boolean;
    @Input() showClauses: boolean;
    @Input() dataEditor: string;
    @Input() forceReadOnlyInsteadPermissions: boolean;
    @Input() editorReadOnly: boolean;
    @Input() initialHeight = 350;
    @Input() cssModalClassName: string;
    @Input() isDocumentSameOrganization: boolean;
    @Input() editorInModal: boolean;
    @Input() showInsertClausesButton: boolean;
    @Input() addSignatureAnimation: boolean;
    @Input() set userPermissions(value: UserPermissionsOnDocumentDTO) {
        this.configureReadOnlyAndFieldsEdition(value);
        this.userPermissionsValue = value;
    }
    @Input() setReadOnlyInputsInClauses = false;
    @Input() setReadOnlyInInteractiveForms = false;
    @Input() insertClauseModalComponent: any;

    @Output() addSignatureAnimationChange = new EventEmitter<boolean>();
    @Output() changeContent = new EventEmitter<EditorDocumentParts>();
    @Output() editorReady = new EventEmitter<boolean>();
    @Output() afterDataReady = new EventEmitter<void>();
    @Output() navigateToClause = new EventEmitter<string>();

    public hasComments: boolean;
    public showTextComments: boolean;
    public defaultSignatureRole = '';
    public currentSignatureId = '';
    public roleCanBeRepeated = '';
    public isAddingSignature = true;
    public modalMessage: string;
    public showButtonTop = false;

    public readonly textSelectionInsertMessage = $localize`:@@InsertarClausulaTextoMensaje:No puede haber texto seleccionado para insertar una cláusula de la Biblioteca`;
    public readonly modifiedClauseMessage = $localize`:@@EditarClausulaEnEditorMensaje:Si modifica el texto de la cláusula, solo se modificará en este documento, no en la cláusula original de la Biblioteca.`;
    public readonly removeClauseMessage = $localize`:@@EliminarClausulaEnEditorMensaje:La cláusula solo será eliminada en este documento, pero no se eliminará de la Biblioteca de cláusulas.`;
    public readonly canNotAddCommentsMessage = $localize`:@@canNotAddCommentsMessage:No puede añadir comentarios mientras está maximizado`;

    private documentPartsForEditor: EditorDocumentParts = new EditorDocumentParts();
    private userPermissionsValue: UserPermissionsOnDocumentDTO;

    private signatureEditionLock = 0;
    private activateTextAndSelectInputs = false;
    private initialPendingTrackChangesCount = 0;
    private currentPendingTrackChangesCount = 0;
    private resolveTrackChangesPressed = false;
    private lastDialog: MatDialogRef<any, any>;
    public editorInstance: any;
    private md5: Md5;
    private users: UserPlugin[] = [];
    private currentIndexTracker = 0;
    private isDestroying = false;
    private isLoadingHtml = true;
    private currentClauseId;
    private commentButtonEvent: any;
    private currentSignatureNumber: number;

    private isCmcConfig = false;

    private SIGNATURE_CLASS = 'signature-in-editor';
    private SIGNATURE_SIGN_IN_PLACEMENT_CLASS = 'signature-sign-placement';
    private SIGNATURE_SIGN_IN_ROL_CLASS = 'signature-sign-rol';
    private ATTRIBUTE_ROLE_IS_STORED = 'signature-title';
    private SIGNATURE_ID_BASE = 'signature_';

    private readonly maximumReached = $localize`:@@MaximoAlcanzado:Máximo alcanzado`;
    private readonly minimumReached = $localize`:@@MinimoAlcanzado:Mínimo alcanzado`;
    private onDestroy$ = new EventEmitter<void>();

    private setTimeOut: NodeJS.Timeout;
    private setTimeOutOnChange: NodeJS.Timeout;

    constructor(
        public typeUserService: TypeUserService,
        private genericDialogService: GenericDialogService,
        private ckeditorFormsCheckBoxService: CkeditorFormsCheckBoxService,
        private ckeditorFormsRadioService: CkeditorFormsRadioService,
        private ckeditorFormsSelectService: CkeditorFormsSelectService,
        private ckeditorFormsTextAreaService: CkeditorFormsTextAreaService,
        private ckeditorFormsTextFieldService: CkeditorFormsTextFieldService,
        private ckeditorPluginFindService: CkeditorPluginFindService,
        private ckeditorPluginLinkService: CkeditorPluginLinkService,
        private ckeditorPluginTableService: CkeditorPluginTableService,
        private ckeditorPluginHelpNotesService: CkeditorPluginHelpNotesService,
        private ckeditorPluginTableTool: CkeditorPluginTableToolsService,
        private showHideElementsService: IShowHideElementsService,
        private cdRef: ChangeDetectorRef,
        private ckeditorFormsUtilsService: ICkeditorFormsUtilsService,
        private ckeditorFontsService: CkeditorFontsService,
        private ckeditorFormatsService: CkeditorFormatsService,
        private ckeditorStylesCMCService: CkeditorStylesCMCService,
        private pathLocationStrategy: PathLocationStrategy,
        private htmlEventsService: IHtmlEventsService,
        private readonly ckeditorReadOnlyClauseWidgetService: CkeditorReadOnlyClauseWidgetService,
        private transformCleanHtmlService: TransformCleanHtmlService) {
        this.md5 = new Md5();
        this.showTrackChanges = true;
        this.showClauses = true;
        this.hasComments = false;
        this.showTextComments = false;
        this.isDocumentSameOrganization = true;

    }

    public ngOnInit() {
        this.addPlugins();
        this.configureReadOnlyAndFieldsEdition(this.userPermissionsValue);

    }

    public ngOnDestroy(): void {
        this.destroyEditor();
        this.onDestroy$.emit();
    }

    public ngOnChanges(changes: SimpleChanges) {
        const dataEditor = 'dataEditor';
        if (changes[dataEditor] && changes[dataEditor].currentValue !== '') {
            this.isLoadingHtml = true;
            this.documentPartsForEditor.html = changes[dataEditor].currentValue;
        }

        const userPermissions = 'userPermissions';
        const forceReadOnlyInsteadPermissions = 'forceReadOnlyInsteadPermissions';

        if (changes[userPermissions] || changes[forceReadOnlyInsteadPermissions]) {
            this.configureReadOnlyAndFieldsEdition(changes.userPermissions.currentValue);
        }

    }

    public ngAfterViewInit() {
        const MARGIN_WITH_TOP = 100;
        const content = document.getElementById('top');
        Observable.fromEvent(content, 'scroll')
            .map(() => content.scrollTop)
            .subscribe(x => {
                this.showButtonTop = x >= MARGIN_WITH_TOP;
            });
    }

    public ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    public updateContent(html: string) {
        this.editorInstance.loadSnapshot(html);
    }

    public hasUnsavedChanges(): boolean {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        return this.editorInstance.checkDirty();
    }

    public resetUnsavedChanges() {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        this.editorInstance.resetDirty();
        this.resolveTrackChangesPressed = false;
    }

    public resetUndoManager() {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        this.editorInstance.resetUndo();
        this.resolveTrackChangesPressed = false;
    }

    public hasPendingTrackChanges(): boolean {
        const flite = this.editorInstance.plugins.flite?.findPlugin(this.editorInstance);
        if (!flite) {
            return false;
        }

        const changes = this.editorInstance.document.find('span[data-changedata]');
        return changes !== null && changes.count() > 0;
    }

    public acceptAllChanges() {
        if (this.showTrackChanges !== true) {
            return;
        }

        const flite = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
        if (!flite) {
            return;
        }

        flite.acceptAll();
    }

    public removeAllComments() {
        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }
        this.modalMessage = $localize`:@@BorrarTodosLosComentarios:¿Deseas borrar todos los comentarios?`;

        const annotations = lance.getAnnotations();
        annotations.getAllAnnotations().forEach(annotationId => {
            annotations.deleteAnnotation(annotationId);
            this.hasComments = false;
        });
    }

    public addCurrentUserInfoWithPicture(newUser: UserInfoDTO, imageUrl: string): void {
        const user = this.getUserInfo(newUser);
        if (imageUrl) {
            user.picture = imageUrl;
        }
        this.users.push(user);
        this.addUsersToComments();

        this.setCurrentUserToComments(user);
        this.setCurrentUserPermissionsToUpdateDelete(user);
        this.setCurrentUserToFlite(user);
    }

    public addUserWithPicture(newUser: UserDTO, imageUrl: string): void {
        const user = this.getUser(newUser);
        if (imageUrl) {
            user.picture = imageUrl;
        }
        this.users.push(user);
        this.addUsersToComments();

    }

    public recalculateRoles() {
        this.lockSignatureEdition();
        let numberNextSignature = 1;
        const signingRoleBase = $localize`:@@Firmante:Firmante `;
        const signatureList = this.editorInstance.document.$.querySelectorAll('.' + this.SIGNATURE_CLASS);
        const roles: string[] = [];

        for (const signature of signatureList) {
            const role: string = signature.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);
            signature.setAttribute('id', this.SIGNATURE_ID_BASE + numberNextSignature);

            if (role.startsWith(signingRoleBase)) {
                const rolNode = signature.querySelector('.' + this.SIGNATURE_SIGN_IN_ROL_CLASS) as HTMLElement;
                const rolPlacement = signature.querySelector('.' + this.SIGNATURE_SIGN_IN_PLACEMENT_CLASS);
                if ((rolNode.innerHTML === '' || rolNode.innerHTML === '<br>') && role !== signingRoleBase + numberNextSignature) {
                    const newRole: string = signingRoleBase + numberNextSignature;
                    signature.setAttribute(this.ATTRIBUTE_ROLE_IS_STORED, newRole);
                    rolPlacement.innerHTML = '[' + newRole + ']';
                }
            }

            numberNextSignature++;
        }

        this.currentSignatureNumber = numberNextSignature;
        this.sendDataChange(null);
        this.unlockSignatureEdition();

        return roles;
    }

    public updateDataInEditor() {
        if (this.editorInstance === undefined || this.editorInstance.textEditor === undefined) {
            return;
        }

        this.editorInstance.textEditor.setData(this.dataEditor);
        setTimeout(() => {
            this.editorInstance.resetDirty();
        });

    }

    public getCurrentData(): string {
        return this.editorInstance.getData();
    }

    public hasResolvedTrackChanges(): boolean {
        const flite = this.editorInstance.plugins.flite?.findPlugin(this.editorInstance);
        if (!flite) {
            return false;
        }

        return this.resolveTrackChangesPressed;
    }

    public onEditorReady(event: CKEditor4.EventInfo) {

        this.editorInstance = event.editor;
        this.subscribeCommentsExternalPluginsEvents(event);

        this.configureTrackChanges();
        this.setReadOnly();
        this.configureToolbars();
        this.configureClausesButtons();
        this.configureSignatureButton();
        this.ckeditorPluginTableTool.configureTableToolsPlugin(this.editorInstance);

        /* Mostrar plegados los rBox que contengan nota de ayuda */
        /* IMPORTANTE: Solo para los documentos antiguos.
            Para los documentos nuevos la funcion se debe ejecutar en editorInstance.on('change')
            (Habrá que eliminarlo de aquí)
        */
        this.rBoxHideContent();
        const context = this;
        this.editorInstance.on('beforeDestroy', () => {
            context.isDestroying = true;
            context.cdRef.detectChanges();
        });

        this.editorInstance.on('afterSetData', (event) => {
            this.isLoadingHtml = true;
            if (!!event.data && event.data.dataValue !== this.transformCleanHtmlService.initializeHtmlEditor()) {
                context.disableConfigModalPlumis();
                context.updateComments();
            } else {
                event.stop();
            }
        });

        this.editorInstance.on('afterCommandExec', (e) => {
            if (e.data.name === 'undo') {
                if (context.editorInstance.undoManager.index === 0) {
                    context.resetUnsavedChanges();
                }
            }
        });

        this.editorInstance.on('maximize', (e) => {

            for (const toolbar of this.editorInstance.toolbar) {
                if (toolbar.name === 'lance') {
                    const commentButton = toolbar.items[0];

                    if (!commentButton) {
                        continue;
                    }

                    if (e.data === 1) { // Maximizar
                        this.commentButtonEvent = commentButton.click;
                        commentButton.click = () => {
                            //  this.openModalCanNotAddCommentsMessage.nativeElement.click();
                        };
                    } else if (e.data === 2) {  // Restaurar
                        commentButton.click = this.commentButtonEvent;
                    }
                }
            }

        });

        setTimeout(() => {
            context.commentFunction();
            context.cargaCheck();
            context.disableConfigModalPlumis();
            context.changeTooltipFromButton();
            context.resetEditor();
        });

        // https://ckeditor.com/old/forums/CKEditor/Event-when-deleting-a-widget
        this.editorInstance.on('change', () => {

            if (this.setTimeOutOnChange) {
                clearTimeout(this.setTimeOutOnChange);
            }

            this.setTimeOutOnChange = setTimeout(() => {

                context.setDefaultDiv();
                const instances = Object.values(context.editorInstance.widgets.instances) as any;
                for (const widget of instances) {
                    const id = widget.element.$.getAttribute('id');
                    const widgetElement = context.editorInstance.document.findOne('#' + id);
                    if (!widgetElement) {
                        context.editorInstance.widgets.destroy(widget);
                    }
                }

                if (!context.isCmcConfig) {
                    if (context.getNumSignatures() !== context.currentSignatureNumber) {
                        context.recalculateRoles();
                    }

                    context.setInputsEditableOrReadOnly(context.editorInstance);
                    /* Mostrar plegados los rBox que contengan notas de ayuda */
                    context.rBoxHideContent();
                }
                context.treatAbstracts(context.editorInstance);
                context.setInteractiveFormsAsReadOnly(context.editorInstance);
                context.setInputsInClausesAsReadOnly(context.editorInstance);
            }, 300);
        });

        this.editorInstance.on('paste', (evt) => {
            evt.stop();

            context.changeRepitedRolesToDefault(evt);
            if (!context.isCmcConfig && this.getNumSignatures() !== context.currentSignatureNumber) {
                context.recalculateRoles();
            }

            const newPasteContent = deleteAndNewIdsHtmlStructure(evt.data.dataValue, '*');
            evt.editor.insertHtml(newPasteContent);

        });

        this.editorInstance.on('selectionChange', (eventSelectionChange) => {
            const selectedElement = eventSelectionChange.data.selection.getStartElement();
            const parents = selectedElement.getParents().reverse();
            const noFound = -1;

            const elementToIgnoreBecauseModifiedByLancePlugin = 'annotation';
            if (selectedElement?.getName() === elementToIgnoreBecauseModifiedByLancePlugin) {
                return;
            }

            for (const parentElement of parents) {
                if (!parentElement.getAttribute('class')) {
                    continue;
                }

                if (parentElement.getAttribute('class').indexOf('clause-in-editor') !== noFound) {
                    const clauseId = parentElement.getAttribute('id');
                    if (!clauseId) {
                        return;
                    }
                    if (this.currentClauseId !== clauseId) {
                        if (this.currentClauseId !== '') {
                            this.deleteIfEmptyWidget();
                        }

                    }

                    this.currentClauseId = clauseId;
                    return;
                }
            }

            if (this.currentClauseId !== '') {
                this.deleteIfEmptyWidget();
            }

            this.currentClauseId = '';

        });
        this.ckeditorFormsSelectService.configureSelectDobleClick(this.editorInstance);

        this.updateComments();
        this.editorReady.emit(true);
    }

    public resetEditor(): void {
        this.resetUndoManager();
        this.resetUnsavedChanges();
    }

    public sendDataChangeWithoutDelay(event) {
        this.sendDataChangeWithEditorInstance(event, this.editorInstance, this.changeContent,
            this.documentPartsForEditor, this);
    }

    public sendDataChange(event) {
        const that = this;
        if (this.setTimeOut) {
            clearTimeout(this.setTimeOut);
        }
        this.setTimeOut = setTimeout(() => {
            that.sendDataChangeWithEditorInstance(event, that.editorInstance, that.changeContent,
                that.documentPartsForEditor, that);
        }, 300);
    }

    public goToPreviousChange() {
        const flite = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
        if (!flite) {
            return;
        }

        const changes = this.editorInstance.document.find('span[data-changedata]');
        if (changes === null || changes.count() < 1) {
            return;
        }

        this.currentIndexTracker--;
        if (this.currentIndexTracker < 0 || this.currentIndexTracker >= changes.count()) {
            this.currentIndexTracker = changes.count() - 1;
        }

        const elementSelected = changes.getItem(this.currentIndexTracker);
        if (elementSelected) {
            elementSelected.scrollIntoView({ block: 'center' });
        }
        const sel = this.editorInstance.getSelection();
        sel.selectElement(elementSelected);
        const ranges = sel.getRanges();
        ranges[0].setStart(elementSelected.getFirst(), 0);
        ranges[0].setEnd(elementSelected.getFirst(), 0); // cursor
    }

    public goToNextChange() {
        const flite = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
        if (!flite) {
            return;
        }

        const changes = this.editorInstance.document.find('span[data-changedata]');
        if (changes === null || changes.count() < 1) {
            return;
        }

        this.currentIndexTracker++;
        if (this.currentIndexTracker >= changes.count()) {
            this.currentIndexTracker = 0;
        }
        const elementSelected = changes.getItem(this.currentIndexTracker);
        elementSelected.scrollIntoView({ block: 'center' });
        const sel = this.editorInstance.getSelection();

        sel.selectElement(elementSelected);
        const ranges = sel.getRanges();
        ranges[0].setStart(elementSelected.getFirst(), 0);
        ranges[0].setEnd(elementSelected.getFirst(), 0); // cursor
    }

    public openCreateClause() {
        const newEditor = this.editorInstance;
        const config = this.getModalConfig();
        const data: any = {
            template: NewClauseModalComponent,
            dialogTitle: $localize`:@@CrearUnaNuevaClausula: Crear una nueva cláusula`,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: 'Guardar',
            dialogCloseButon: 'Cancelar',
            dialogContentGetResultPropertyName: 'clauseToEdit',
            primaryButtonContentObservableName: 'isValid',
            primaryButtonActionBeforeCloseObservableName: 'doSaveObserver',
            primaryButtonActionAfterCloseObservableName: 'afterSaveObserver'

        };
        this.lastDialog = this.genericDialogService.openTemplateWithConfigAndData(NewClauseModalComponent, config, data);
        this.lastDialog.afterClosed().subscribe((clauseSave: ClauseDTO) => {
            if (!clauseSave) {
                return;
            }

            this.insertNewClause(clauseSave, newEditor);
        });
    }

    public isAbleToInsertClauseLibrary(): boolean {
        const editor = this.editorInstance;
        const sel = editor.getSelection();
        const elementSelected = sel.getSelectedText();
        return elementSelected === undefined || elementSelected === '';
    }

    public isAbleToInsertClauseTreeLibrary(): boolean {
        const editor = this.editorInstance;
        const sel = editor.getSelection();
        const elementSelected = sel.getSelectedText();
        return elementSelected === undefined || elementSelected === '';
    }

    public openInsertSignature() {
        this.addSignatureAnimation = false;
        this.isAddingSignature = true;
        const editor = this.editorInstance;
        this.defaultSignatureRole = $localize`:@@Firmante:Firmante ` + ' ' + (this.getNumSignatures() + 1);

        this.openSignature(this.isAddingSignature).afterClosed().subscribe((result) => {
            this.lockSignatureEdition();
            this.insertNewSignature(result, editor);
            this.unlockSignatureEdition();
        });

    }

    public openEditSignature() {
        const editor = this.editorInstance;
        this.isAddingSignature = false;

        this.openSignature(this.isAddingSignature).afterClosed().subscribe((signature: SignatureModel) => {
            this.defaultSignatureRole = '';
            this.roleCanBeRepeated = '';
            this.currentSignatureId = '';
            if (!signature) {
                return;
            }

            const showRole = signature.role !== undefined && signature.role !== null && signature.role.trim() !== '';
            const roleAndRoleToShow = this.getSignatureRoleAndRoleToShow(signature, showRole);
            const node = editor.document.$.querySelector('#' + roleAndRoleToShow.id);
            node.innerHTML = this.addSignatureTemplateContent(roleAndRoleToShow.signatureRole, roleAndRoleToShow.roleToShow);
            node.setAttribute(this.ATTRIBUTE_ROLE_IS_STORED, roleAndRoleToShow.signatureRole);

            this.recalculateRoles();
        }, (reason) => {
            this.defaultSignatureRole = '';
            this.roleCanBeRepeated = '';
            this.currentSignatureId = '';
        });
    }

    public openSignature(editSignature: boolean) {
        this.isAddingSignature = editSignature;
        this.addSignatureAnimation = false;
        if (!this.defaultSignatureRole) {
            this.defaultSignatureRole = $localize`:@@Firmante:Firmante ` + ' ' + (this.getNumSignatures() + 1);
        }

        const config = this.genericDialogService.getSmallDialogConfig();
        const data: any = {
            template: SignatureConfigurationComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: this.isAddingSignature ? $localize`:@@InsertarFirma:Añadir firma` : $localize`:@@EditarFirmaTitulo:Editar firma`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.Warning,
            dialogTitle: this.isAddingSignature ? $localize`:@@InsertarFirma:Añadir firma` : $localize`:@@EditarFirmaTitulo:Editar firma`,
            isAdding: this.isAddingSignature,
            defaultRole: this.defaultSignatureRole,
            signatureId: this.currentSignatureId,
            rolCanBeRepeated: this.roleCanBeRepeated,
            currentRoles: this.getCurrentRoles(),
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'signature',
            dialogContentGetCloseResultPropertyName: 'signature',
        };

        this.lastDialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);

        return this.lastDialog;

    }

    public editSignature(signatureNode: HTMLElement) {
        if (this.isSignatureditionLocked()) {
            return;
        }
        const editor = this.editorInstance;
        if (signatureNode === null) {
            this.insertSignatureButton.nativeElement.click();
            return;
        }

        const role = signatureNode.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);
        const id = signatureNode.getAttribute('id');
        this.defaultSignatureRole = role;
        this.currentSignatureId = id;
        this.roleCanBeRepeated = role;
        this.editSignatureButton.nativeElement.click();
    }

    public openInsertClauseFromLibrary() {
        const editor = this.editorInstance;
        const config = this.getListModalConfig();
        const data: any = {
            template: ListClauseModalComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };

        this.lastDialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);

        this.lastDialog.afterClosed().subscribe((result) => {

            if (result === null) {
                return;
            }

            this.insertSeveralClauses(result, editor);
        });
    }

    public openInsertClauseFromTreeLibrary() {
        const editor = this.editorInstance;
        if (editor.readOnly) {
            return;
        }
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: ClausesTreeTextComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };

        this.lastDialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);

        this.lastDialog.afterClosed().subscribe((result) => {

            if (result === null) {
                return;
            }

            this.insertSeveralClauses(result, editor);
        });
    }

    public getListModalConfig(): GenericDialogConfig {
        const config = this.genericDialogService.getDefaultDialogConfig();
        config.width = '50vw';
        config.height = '90vh';
        return config;
    }

    public showMessage(message: string): void {
        this.genericDialogService.showMessage(message);
    }

    public dataReady(event: CKEditor4.EventInfo) {
        if (this.isLoadingHtml) {

            const editorInstance = event.editor;

            if (editorInstance === undefined || editorInstance.document === undefined) {
                return;
            }

            this.configureInputsIfAreActiveOrNot(editorInstance);
            this.ckeditorFormsUtilsService.manageAnchorLinks(editorInstance);

            if (this.editorReadOnly) {
                const insertSignatureButton = editorInstance.getCommand('insertSignatureButton');
                if (insertSignatureButton) {
                    insertSignatureButton.enable();
                }
            } else {
                this.configurePlumis(editorInstance);
                this.configureHelpNote(editorInstance);
            }

            this.resetEditor();
            this.isLoadingHtml = false;

            this.afterDataReady.emit();
        }
    }

    public getCurrentRoles(): string[] {
        const signatureList = this.editorInstance.document.$.querySelectorAll('.' + this.SIGNATURE_CLASS);
        const roles: string[] = [];
        for (const signature of signatureList) {
            const role = signature.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED, false);
            roles.push(role);
        }
        return roles;
    }

    public canDeleteAllComments(): boolean {
        return this.hasComments && this.userPermissionsValue?.canDeleteComments && (!this.hideAllButtons || this.showComments);
    }

    public deleteAllComments() {
        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }
        const message = $localize`:@@BorrarTodosLosComentarios:¿Deseas borrar todos los comentarios?`;

        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result === true) {
                const annotations = lance.getAnnotations();
                annotations.getAllAnnotations().forEach(annotationId => {
                    annotations.deleteAnnotation(annotationId);
                });
                this.hasComments = false;
            }
        });
    }

    public hideColumnComments() {
        return this.hideCommentsColumn || !this.showComments || (!this.hasComments && !this.userPermissionsValue?.canComment);
    }

    public openInsertClauseCMC() {
        this.ckeditorReadOnlyClauseWidgetService.openInsertClauseModal(this.editorInstance, this.md5, this.insertClauseModalComponent);
    }

    public scrollToElement(elementId: string): void {
        const element = document.getElementById(elementId);
        element?.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    public showEditor(): boolean {
        return !this.isDestroying && this.dataEditor !== null && this.dataEditor !== undefined;
    }

    private configureReadOnlyAndFieldsEdition(userPermissions: UserPermissionsOnDocumentDTO) {
        this.setReadOnlyUsingPermission(userPermissions);

        if (this.forceReadOnlyInsteadPermissions) {
            this.editorReadOnly = this.forceReadOnlyInsteadPermissions;
        }

        this.setReadOnly();
        this.setActivateTextAndSelectInputsUsingPermissions(userPermissions);
        this.configureInputsIfAreActiveOrNot(this.editorInstance);
        this.configureClausesButtons();
        this.configureSignatureButton();
    }

    private setActivateTextAndSelectInputsUsingPermissions(userPermissions: UserPermissionsOnDocumentDTO) {
        if (userPermissions && !userPermissions.canEdit && userPermissions.canOnlyFillTextInputs) {
            this.activateTextAndSelectInputs = true;
        } else {
            this.activateTextAndSelectInputs = false;
        }
    }

    private setReadOnlyUsingPermission(userPermissions: UserPermissionsOnDocumentDTO) {
        if (userPermissions && userPermissions.canEdit && !userPermissions.canOnlyFillTextInputs) {
            this.editorReadOnly = false;
        } else {
            this.editorReadOnly = true;
        }
    }

    private setDefaultDiv() {
        if (this.isCmcConfig) {
            if (this.dataEditor === '') {
                this.dataEditor = this.transformCleanHtmlService.initializeHtmlEditor();
            }
            else if (this.dataEditor.indexOf('id="tBody"') === -1) {
                this.dataEditor = this.transformCleanHtmlService.initializeHtmlEditor().replace('<p></p>', this.dataEditor);
            }
        }
    }

    private rBoxHideContent() {
        const jquery = (window as any).$;
        const radioElements = this.editorInstance.document.$.querySelectorAll('[class="rBox"]');
        for (const radio of radioElements) {
            if (jquery(radio).find('.rhDesc').length) {
                jquery(radio).addClass('wHelp');
            }
        }
    }

    private destroyEditor() {
        if (this.editorInstance) {
            if (!this.editorInstance.isDestroyed()) {
                this.editorInstance.destroy();
            }
        }
    }

    private deleteIfEmptyWidget() {
        if (!this.currentClauseId) {
            return;
        }

        const divClauseId = this.editorInstance.document.findOne('#' + this.currentClauseId);
        if (!divClauseId) {
            return; // already deleted
        }

        if (divClauseId.getText().trim() === '') { // adittional checks when empty inputs?

            const instances = Object.values(this.editorInstance.widgets.instances) as any;
            for (const widget of instances) {
                const id = widget.element.$.getAttribute('id');

                if (id === this.currentClauseId) {
                    this.editorInstance.widgets.destroy(widget);
                    divClauseId.getParent().remove();

                    return;
                }
            }
        }
    }

    private sendDataChangeWithEditorInstance(event, editorInstance: any, changeContent: EventEmitter<EditorDocumentParts>,
                                             editorDocumentParts: EditorDocumentParts, context: any) {
        if (editorInstance === undefined) {
            return;
        }
        const currentData = event && event.editor ? event.editor.getData() : editorInstance.getData();
        const domParser = new DOMParser();
        const documentHtml = domParser.parseFromString(currentData, 'text/html');

        const inputsList = documentHtml.querySelectorAll('input, textarea, select, radio, checkbox');

        inputsList.forEach((input: Element) => {
            input.setAttribute('contenteditable', 'true');
            input.removeAttribute('disabled');
        });

        editorDocumentParts.html = documentHtml.documentElement.outerHTML;
        changeContent.emit(editorDocumentParts);
    }

    public removeReadOnlyInputsTextAndSelectEditablesDocument(contentHtml: string): string {
        const domParser = new DOMParser();
        const documentHtml = domParser.parseFromString(contentHtml, 'text/html');

        const inputsList = documentHtml.querySelectorAll('input, textarea, select, radio, checkbox');

        inputsList.forEach((input: Element) => {
            input.setAttribute('contenteditable', 'true');
            input.removeAttribute('disabled');
        });

        return documentHtml.documentElement.outerHTML;
    }

    private addPlugins() {
        this.setSkin();
        this.allowExternalHtmlContent();
        this.addGenericPlugin();

        if (this.showSignatureButton) {
            this.insertSignaturePlugin();
        }

        this.addTrackFliteExternalPlugin();
        this.addCommentsExternalPlugin();

        if (this.isCmcConfig) {
            if (CKEDITOR.plugins.registered.magicline && !(CKEDITOR.config.removePlugins as string).includes('magicline')) {
                CKEDITOR.config.removePlugins = (CKEDITOR.config.removePlugins as string).concat(',', 'magicline');
            }

            this.ckeditorReadOnlyClauseWidgetService.addPlugin(this.insertClauseCmc);
            this.ckeditorPluginTableService.configurePlugin();
            this.ckeditorFormsTextFieldService.configureTextFieldNoModal();
            this.ckeditorPluginHelpNotesService.configureHelpNotesPlugin();
            this.ckeditorReadOnlyClauseWidgetService.getNavigateToClauseObservable()
                .pipe(takeUntil(this.onDestroy$)).subscribe((clauseIdToNavigate: string) => {
                    this.navigateToClause.emit(clauseIdToNavigate);
                });
            CKEDITOR.config.forcePasteAsPlainText = true;
        } else {
            this.insertClauseWidgetPlugin();
        }

        this.configureToolbars();
        this.configureSize();
        this.addOpenLink();
        this.addIndentBlock();
        this.addbase64Image();
        this.ckeditorFontsService.configureFonts();
        this.ckeditorFormatsService.addPluginFormats();
        this.ckeditorStylesCMCService.addStylesCMC();
    }

    private setSkin() {
        CKEDITOR.config.skin = 'office2013';
    }

    private allowExternalHtmlContent() {
        CKEDITOR.config.allowedContent = true;
        CKEDITOR.config.contentsCss = this.pathLocationStrategy.getBaseHref() + 'css/contractBox.css';

    }

    private configureSize() {
        if (this.initialHeight !== undefined) {
            CKEDITOR.config.height = this.initialHeight;
        }
        else {
            CKEDITOR.config.autoGrow_maxHeight = undefined;
            CKEDITOR.config.autoGrow_minHeight = undefined;
        }
    }

    private setReadOnly() {
        if (this.editorInstance) {
            this.editorInstance.setReadOnly(this.editorReadOnly);
            if (this.showTrackChanges) {
                this.editorInstance.getCommand('previousChangeCommand').enable();
                this.editorInstance.getCommand('nextChangeCommand').enable();
            }
        }
    }

    private configureToolbars() {
        CKEDITOR.config.customConfig = this.customConfig;
        CKEDITOR.config.baseFloatZIndex = 1051;
        CKEDITOR.config.canAcceptOrRejectTrackChanges = this.userPermissionsValue?.canAcceptOrRejectTrackChanges;

        if (!this.hideAllButtons) {
            CKEDITOR.config.toolbar = [
                { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'CopyFormatting', 'RemoveFormat', '-', 'Undo', 'Redo'] },
                {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'TextColor', 'BGColor']
                },
                {
                    name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'JustifyLeft',
                        'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language']
                },
                {
                    name: 'styles', items: environment.production ?
                        ['Font', 'FontSize'] :
                        ['Styles', 'Format', 'Font', 'FontSize']
                },
                { name: 'editing', items: ['Find', 'Replace', 'SelectAll'] },
                {
                    name: 'insert', items: ['base64image', 'Flash', 'Table', 'Smiley', 'SpecialChar',
                        'PageBreak', 'Iframe', 'Link', 'Unlink']
                },
                { name: 'forms', items: ['TextField', 'Select', 'Checkbox', 'Radio'] },
                { name: 'clauses', items: this.showClauses ? ['insertClauseAndModal', 'clauseFromLibrary'] : [] },
                { name: 'signature', items: ['insertSignatureAndModal'] },
                {
                    name: 'flite', items: this.userPermissionsValue?.canAcceptOrRejectTrackChanges ?
                        ['flite-acceptall', 'flite-acceptone', 'flite-rejectall',
                            'flite-rejectone', 'previousChangeFliteButton', 'nextChangeFliteButton',
                            'flite-toggleshow', 'flite-toggletooltips'] :
                        ['previousChangeFliteButton', 'nextChangeFliteButton',
                            'flite-toggleshow', 'flite-toggletooltips']
                },
                { name: 'lance', items: ['annotate'] },
                { name: 'tools', items: ['Maximize'] }
            ];

        } else if (this.showTrackChanges) {
            CKEDITOR.config.toolbar = [
                { name: 'flite', items: ['previousChangeFliteButton', 'nextChangeFliteButton', 'flite-toggleshow', 'flite-toggletooltips'] }
            ];
        } else if (this.showComments) {
            CKEDITOR.config.toolbar = [
                { name: 'lance', items: ['annotate'] }
            ];
        } else if (this.isCmcConfig) {
            CKEDITOR.config.allowedContent = true;
            CKEDITOR.config.contentsCss = this.pathLocationStrategy.getBaseHref() + 'css/contractBox/Docs.css';
            CKEDITOR.config.toolbar = [
                { name: 'clipboard', items: ['Copy', 'Paste', 'Undo', 'Redo'] },
                { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
                { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
                { name: 'editing', items: ['Find', 'Replace'] },
                { name: 'links', items: ['Anchor'] },
                { name: 'insert', items: ['Link', 'Unlink'] },
                { name: 'styles', items: ['Styles'] },
                { name: 'forms', items: ['InsertInput', 'Select', 'InsertHelpNotes'] },
                { name: 'clauses', items: this.showClauses ? ['clauseFromTree'] : [] },
                {
                    name: 'flite', items: this.userPermissionsValue?.canAcceptOrRejectTrackChanges ?
                        ['flite-acceptall', 'flite-acceptone', 'flite-rejectall', 'flite-rejectone', 'previousChangeFliteButton', 'nextChangeFliteButton', 'flite-toggleshow', 'flite-toggletooltips'] :
                        ['previousChangeFliteButton', 'nextChangeFliteButton', 'flite-toggleshow', 'flite-toggletooltips']
                },
                { name: 'lance', items: ['annotate'] },
                { name: 'insert', items: ['base64image', 'Table'] }
            ];

        } else {
            CKEDITOR.config.toolbar = [];
        }

        if (!environment.production) {
            CKEDITOR.config.toolbar.push({ name: 'documents', items: ['Source'] });
        }
    }

    private configureSignatureButton() {
        if (!this.editorInstance) {
            return;
        }

        if (!this.showSignatureButton) {
            this.editorInstance.getCommand('insertSignatureAndModal').disable();
        }

    }

    private configureClausesButtons() {
        if (!this.editorInstance) {
            return;
        }

        if (!this.showClauses) {
            return;
        }

        if ((this.userPermissions && !this.userPermissionsValue?.canAddClauses) || !this.showAddNewClause) {
            this.editorInstance.getCommand('insertClauseAndModal')?.disable();
        }

        if (!this.showAddNewClause) {
            this.editorInstance.getCommand('insertClauseFromLibrary')?.disable();
        }
        if (!this.showAddNewClauseTree) {
            this.editorInstance.getCommand('insertClauseFromTree')?.disable();
        }

    }

    private configureTrackChanges(): void {
        if (this.showTrackChanges !== true) {
            return;
        }

        const flite = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
        if (!flite) {
            return;
        }

        flite.config.styleUrls = ['css/trackChanges.css', 'css/tippy.css'];
        flite.config.timezone = 'UTC';
        if (this.showButDisableTrackChanges) {
            flite.config.isTracking = false;
        }

        this.initialPendingTrackChangesCount = flite.countChanges();

        this.editorInstance.on('flite:accept', (e: any) => {
            this.resolveTrackChangesPressed = true;
            const flitePlugin = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
            this.currentPendingTrackChangesCount = flitePlugin.countChanges();

        });

        this.editorInstance.on('flite:reject', (e: any) => {
            this.resolveTrackChangesPressed = true;
            const flitePlugin = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
            this.currentPendingTrackChangesCount = flitePlugin.countChanges();

        });

        this.resolveTrackChangesPressed = false;
    }

    private configureInputsIfAreActiveOrNot(editorInstance: any): void {
        if (!editorInstance) {
            return;
        }

        this.setInputsEditableOrReadOnly(editorInstance);
        this.setInteractiveFormsAsReadOnly(editorInstance);
        this.setInputsInClausesAsReadOnly(this.editorInstance);
    }

    private configureHelpNote(editorInstance: any) {
        const helpIcons = editorInstance.document.$.querySelectorAll('[class="nh"]');

        for (const icon of helpIcons) {
            const nextElement = icon.nextElementSibling;
            if (nextElement) {
                icon.setAttribute('title', nextElement.innerText);
            }
        }
    }

    private configurePlumis(editorInstance: any) {
        const plumisButtons = editorInstance.document.$.querySelectorAll('.opt a[title]');

        for (const button of plumisButtons) {
            const jsonMime = new RegExp(/"([^"]*)"|'([^']*)'/g);
            const clickValue = button.getAttribute('data-cke-pa-onclick');
            const idValues = jsonMime.exec(clickValue);

            if (idValues && idValues.length === 3) {
                if (clickValue.indexOf('add') !== -1) {
                    button.addEventListener('click', (event) => {
                        this.addPlumisGroup(idValues[2], editorInstance);
                        this.sendDataChangeWithEditorInstance(null, editorInstance, this.changeContent, this.documentPartsForEditor, this);
                    }, true);
                }

                if (clickValue.indexOf('supp') !== -1) {
                    button.addEventListener('click', (event) => {
                        this.removePlumisGroup(idValues[2], editorInstance);
                        this.changeContent.emit(this.documentPartsForEditor);
                    }, true);
                }
            }
        }
    }

    private addOpenLink() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/openlink/';
        CKEDITOR.plugins.addExternal('openlink', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('openlink')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('openlink,');
        }
    }

    private addIndentBlock() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/indentblock/';
        CKEDITOR.plugins.addExternal('indentblock', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('indentblock')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('indentblock,');
        }
    }

    private addbase64Image() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/base64image/';
        if (this.isCmcConfig) {
            CKEDITOR.plugins.addExternal('base64image', mydir, 'pluginCmc.js');
        } else {
            CKEDITOR.plugins.addExternal('base64image', mydir, 'plugin.js');
        }

        if (!(CKEDITOR.config.extraPlugins as string).includes('base64image')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('base64image,');
        }
    }

    private addGenericPlugin() {
        CKEDITOR.config.removePlugins = 'showborders,elementspath,resize';  // remove botton bar

        const extraPlugin = 'forms,copyformatting,find,selectall,justify,font,format,colorbutton,autogrow,';
        if (!this.isCmcConfig) {
            extraPlugin.concat('div,');
        }

        for (const element of extraPlugin.split(',')) {
            if ((CKEDITOR.config.extraPlugins as string).includes(element)) {
                continue;
            }
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat(element + ',');
        }

        this.ckeditorFormsTextFieldService.configureTextField();
        this.ckeditorFormsCheckBoxService.configureCheckBox();
        this.ckeditorFormsRadioService.configureRadio();
        this.ckeditorFormsTextAreaService.configureTextArea();
        this.ckeditorFormsSelectService.configureSelect();
        this.ckeditorPluginFindService.configureFindPlugin();
        this.ckeditorPluginLinkService.configureLinkPlugin();
    }

    private insertSignaturePlugin() {

        if (!(CKEDITOR.config.extraPlugins as string).includes('insertSignature')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('insertSignature,');
        }

        delete CKEDITOR.plugins.registered.insertSignature;

        const context = this;

        CKEDITOR.plugins.add('insertSignature', {
            requires: 'widget',
            icons: '',
            init: (editor, data) => {
                editor.addContentsCss(this.pathLocationStrategy.getBaseHref() + 'css/signatureInEditor.css');

                editor.widgets.add('insertSignature', {
                    button: 'insertSignature',
                    template: this.addSignatureTemplate('', true),
                    requiredContent: 'div(' + this.SIGNATURE_CLASS + ');',
                    draggable: false,
                    dialog: null,

                    destroy(evt) {
                        if (context.isDestroying) {
                            return;
                        }
                        context.recalculateRoles();
                    },
                    key(evt) {
                        if (!context.editorInstance.plugins.flite) {
                            return;
                        }
                        const flite = context.editorInstance.plugins.flite.findPlugin(context.editorInstance);

                        evt.stop();
                        evt.cancel();

                        switch (evt.data.keyCode) {
                            case 8:
                                return flite._tracker._deleteContents(!1, null, !1);
                            case 46:
                                return flite._tracker._deleteContents(!0, null, !1);
                        }

                    },
                    upcast: (element) => {
                        return element.name === 'div' && element.hasClass(this.SIGNATURE_CLASS);
                    },
                    edit(evtEdit) {
                        if (context.isSignatureditionLocked()) {
                            return;
                        }

                        const signatureNode = evtEdit.sender.element;
                        context.editSignature(signatureNode);
                        evtEdit.stop();
                        evtEdit.cancel();
                    },

                });

                editor.ui.addButton('insertSignatureAndModal', {
                    label: 'Insertar firma nueva',
                    command: 'insertSignatureAndModal',
                    toolbar: 'signature',
                });

                editor.addCommand('insertSignatureAndModal', {
                    exec: () => {
                        this.insertSignatureButton.nativeElement.click();
                    },
                });
            },
        });
    }

    private insertClauseWidgetPlugin() {
        if (!(CKEDITOR.config.extraPlugins as string).includes('insertClause')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('insertClause,');
        }

        delete CKEDITOR.plugins.registered.insertClause;
        const context = this;

        CKEDITOR.plugins.add('insertClause', {
            requires: 'widget',
            icons: '',
            init: (editor) => {
                editor.addContentsCss(this.pathLocationStrategy.getBaseHref() + 'css/clauseFromLibrary.css');
                editor.widgets.add('insertClause', {
                    button: 'insertClause',
                    template: this.getClauseTemplate('', '', ''),
                    editables: !this.editorReadOnly ? {
                        content: {
                            selector: '.clause-in-editor',
                            allowedContent: 'p[*](*){*};span[*](*){*};strong;em(*);u;s;sub;sup;h1;h2;h3;h4;h5;input[*](*){*};select[*](*){*};ol;ul;option[*](*){*};annotation[*](*);div(ccn);',
                            disallowedContent: this.editorReadOnly ? '' : 'div(clause-in-editor); '
                        }
                    } : undefined,
                    requiredContent: 'div(clause-in-editor); ',
                    draggable: false,
                    destroy() {
                    },
                    key(evt) {
                        if (!context.editorInstance.plugins.flite) {
                            return;
                        }

                        const flite = context.editorInstance.plugins.flite.findPlugin(context.editorInstance);

                        evt.stop();
                        evt.cancel();

                        switch (evt.data.keyCode) {
                            case 8:
                                return flite._tracker._deleteContents(!1, null, !1);
                            case 46:
                                return flite._tracker._deleteContents(!0, null, !1);
                        }
                    },
                    upcast: (element) => {
                        return element.name === 'div' && element.hasClass('clause-in-editor');
                    },

                });

                editor.ui.addButton('insertClauseAndModal', {
                    label: 'Insertar cláusula nueva',
                    command: 'insertClauseAndModal',
                    toolbar: 'forms',
                });

                editor.addCommand('insertClauseAndModal', {
                    exec: () => {
                        this.openModalButton.nativeElement.click();
                    },
                });

                editor.ui.addButton('clauseFromLibrary', {
                    label: 'Insertar cláusula de la Biblioteca',
                    command: 'insertClauseFromLibrary',
                    toolbar: 'forms',
                });

                editor.addCommand('insertClauseFromLibrary', {
                    exec: () => {
                        this.insertClauseFromLibraryButton.nativeElement.click();
                    },
                });

                editor.ui.addButton('clauseFromTree', {
                    label: 'Insertar cláusula de la Biblioteca',
                    command: 'insertClauseFromTree',
                    toolbar: 'forms',
                });

                editor.addCommand('insertClauseFromTree', {
                    exec: () => {
                        this.insertClauseFromTreeButton.nativeElement.click();
                    },
                });
            },
        });
    }

    private getClauseTemplate(clauseId: string, hashDescription: string | Int32Array, textClause: string): string {
        const clauseHtml = '<div id="' + clauseId + '" class="clause-in-editor" ' +
            ' signature-title="' + hashDescription + '">' + textClause + '</div>';
        return clauseHtml;
    }

    private addSignatureTemplate(role: string, showRole: boolean) {
        const signature: SignatureModel = {
            id: '',
            role,
        };

        const roleAndRoleToShow = this.getSignatureRoleAndRoleToShow(signature, showRole);

        const template = '<div id="' + this.SIGNATURE_ID_BASE + roleAndRoleToShow.id + '" class="' + this.SIGNATURE_CLASS + '" '
            + this.ATTRIBUTE_ROLE_IS_STORED + '="' + roleAndRoleToShow.signatureRole + '">' +
            this.addSignatureTemplateContent(roleAndRoleToShow.signatureRole, roleAndRoleToShow.roleToShow);

        return template;
    }

    private getSignatureRoleAndRoleToShow(signature: SignatureModel,
                                          showRol: boolean): { signatureRole: string, roleToShow: string, id: string; } {

        let signatureRole = '';
        let id = '-1';
        if (!signature.id || signature.id.trim() === '') {
            id = (this.getNumSignatures() + 1).toString();
        } else {
            id = signature.id;
        }

        if (!signature.role || signature.role.trim() === '') {
            signatureRole = $localize`:@@Firmante:Firmante ` + (this.getNumSignatures() + 1);
        } else {
            signatureRole = signature.role;
        }

        let roleToShow = '';
        if (showRol) {
            roleToShow = signatureRole;
        }

        return { signatureRole, roleToShow, id };
    }

    private addSignatureTemplateContent(signatureRole: string, rolToShow: string): string {
        return '<div class="signature-content">' +
            '<table>' +
            '<tbody>' +
            '<tr>' +
            '<td class="' + this.SIGNATURE_SIGN_IN_PLACEMENT_CLASS + '">[' + signatureRole + ']</td>' +
            '</tr>' +
            '</tbody>' +
            '<trfoot>' +
            '<td  class="' + this.SIGNATURE_SIGN_IN_ROL_CLASS + '">' + rolToShow + '</td>' +
            '</trfoot>' +
            '</table></div></div> ';
    }

    private addTrackFliteExternalPlugin() {
        if (this.showTrackChanges !== true) {
            // delete CKEDITOR.plugins.registered.flite;
            CKEDITOR.config.extraPlugins = CKEDITOR.config.extraPlugins.replace('flite,', '');
            CKEDITOR.config.extraPlugins = CKEDITOR.config.extraPlugins.replace('extendedFlite,', '');
            return;
        }

        if (!(CKEDITOR.config.extraPlugins as string).includes('flite')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('flite,');
        }

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/flite-cke/flite/';
        CKEDITOR.plugins.addExternal('flite', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('extendedFlite')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('extendedFlite,');
        }

        delete CKEDITOR.plugins.registered.extendedFlite;

        CKEDITOR.plugins.add('extendedFlite', {
            icons: '',
            init: (editor) => {
                editor.addCommand('nextChangeCommand', {
                    exec: () => {
                        this.nextChangeFliteButton.nativeElement.click();
                    },
                });

                editor.addCommand('previousChangeCommand', {
                    exec: () => {
                        this.previousChangeFliteButton.nativeElement.click();
                    },
                });

                editor.ui.addButton('previousChangeFliteButton', {
                    label: 'Cambio anterior',
                    command: 'previousChangeCommand',
                    toolbar: 'flite',
                });

                editor.ui.addButton('nextChangeFliteButton', {
                    label: 'Cambio siguiente',
                    command: 'nextChangeCommand',
                    toolbar: 'flite',
                });
            }
        });
    }

    private addCommentsExternalPlugin() {
        if (this.showComments !== true) {
            CKEDITOR.config.extraPlugins = CKEDITOR.config.extraPlugins.replace('lance,', '');
            return;
        }

        if (!(CKEDITOR.config.extraPlugins as string).includes('lance')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('lance,');
        }

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/lance-tmce/lance/';
        CKEDITOR.plugins.addExternal('lance', mydir, 'plugin.js');
    }

    private addUsersToComments() {
        if (this.showComments !== true) {
            return;
        }

        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }

        const annotations = lance.getAnnotations();
        annotations.addUsers(this.users);
    }

    private setCurrentUserToComments(user: UserPlugin) {

        if (this.showComments !== true) {
            return;
        }

        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }

        const annotations = lance.getAnnotations();
        annotations.setUserId(user.id);
    }

    private setCurrentUserPermissionsToUpdateDelete(user: UserPlugin) {
        if (this.showComments !== true) {
            return;
        }

        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }

        const annotations = lance.getAnnotations();
        let permissions = permissionsEditDeleteConfiguration.permissionsRegisteredNoRevisorUser;

        if (user.permissions[0] === this.typeUserService.guestUser) {

            if (!this.userPermissionsValue || !this.userPermissionsValue.canComment) {
                annotations._setEnabled(false);
                permissions = permissionsEditDeleteConfiguration.permissionsGuestNoRevisorUser;
            } else {
                annotations._setEnabled(true);

                if (!this.userPermissionsValue.canDeleteComments) {
                    permissions = permissionsEditDeleteConfiguration.permissionsGuestRevisorCannotDeleteUser;
                } else {
                    permissions = permissionsEditDeleteConfiguration.permissionsGuestRevisorCanDeleteUser;
                }
            }

            if (this.hideAllButtons && this.forceReadOnlyInsteadPermissions) {
                permissions = permissionsEditDeleteConfiguration.permissionsRegisteredNoRevisorUser;
                annotations._setEnabled(false);
            }
            annotations.setPermissions(permissions);

            return;
        }

        if (!this.userPermissionsValue || !this.userPermissionsValue.canComment) {
            permissions = permissionsEditDeleteConfiguration.permissionsRegisteredNoRevisorUser;
            annotations._setEnabled(false);
        } else {
            annotations._setEnabled(true);
            if (!this.userPermissionsValue.canDeleteComments) {
                permissions = permissionsEditDeleteConfiguration.permissionsRegisteredRevisorCannotDeleteUser;
            } else {
                permissions = permissionsEditDeleteConfiguration.permissionsCreatorRevisorCanDeleteUser;
            }
        }

        if (this.hideAllButtons && this.forceReadOnlyInsteadPermissions) {
            permissions = permissionsEditDeleteConfiguration.permissionsRegisteredNoRevisorUser;
            annotations._setEnabled(false);
        }

        annotations.setPermissions(permissions);
    }

    private setCurrentUserToFlite(user: UserPlugin) {
        if (this.showTrackChanges !== true) {
            return;
        }
        const flite = this.editorInstance.plugins.flite.findPlugin(this.editorInstance);
        if (!flite) {
            return;
        }
        if (flite.userStyle === undefined) {
            flite.userStyle = [];
        }

        if (flite.userStyle[user.id] === undefined) {
            const highestVal = Math.max.apply(null, Object.values(flite.userStyle));
            const realVal = highestVal !== -Infinity ? highestVal : 0;
            flite.userStyle[user.id] = realVal + 1;
        }

        flite.enableAcceptReject(this.userPermissionsValue.canAcceptOrRejectTrackChanges);
        flite.setUserInfo(user);
        flite.startNewSession();
    }

    private getUserInfo(user: UserInfoDTO): UserPlugin {
        const userPlugin: UserPlugin = {
            id: user.guid,
            name: user.surname + ', ' + user.name,
            picture: '',
            permissions: user.permissions
        };

        return userPlugin;
    }

    private getUser(user: UserDTO): UserPlugin {
        const userPlugin: UserPlugin = {
            id: user.id,
            name: user.surname + ', ' + user.name,
            picture: ''
        };

        return userPlugin;
    }

    private subscribeCommentsExternalPluginsEvents(event): void {
        if (this.showComments !== true) {
            return;
        }

        const lance = this.editorInstance.plugins.lance.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }

        const annotations = lance.getAnnotations();

        // No mostrar el texto si hay comentarios
        this.showTextComments = annotations.countAnnotations() < 1;

        // define the options block
        const uiOptions = {
            container: this.comments.nativeElement, // the DOM node in which the annotations ui will construct its elements
            autoGrow: true, // allow comment entry inputs to grow with the text. Defaults to true
            autoScroll: false,
            blurAware: true, // Revert or cancel the comment action if the text input loses focus. Defaults to true.
            // Options added in Lance 1.3
            generateUI: true, // set to true to use the new, automatically generated user interface
            owner: annotations, // if generateUI is true, this must be a reference to an
            // annotations manager owned by an instance of the Lance plugin.
            generateCSS: true, // defaults to true if generateUI is true. If false, don't inject the default css
            alignCommentsToEditor: true, // defaults to true if generateUI is true. If false, don't
            // // apply the code that aligns the selected comment with the the corresponding document location,

        };
        // // Create the user interface controller

        const loop = window.LANCE;  // Important!!! this way, no use tslint to improve or it will fail!!!

        const ui = new loop.AnnotationsUI();

        //  AnnotationsUI();
        // // // // initialize it
        ui.init(uiOptions);
        // // // // no need in this case, since it's already in the options block, but also possible:
        // // // // hook up the Annotations object to the UI we created in the previous step
        ui.setOwner(annotations);

        annotations.on(loop.Annotations.Events.ANNOTATION_CREATED, () => {
            this.sendDataChange(null);
        });
        annotations.on(loop.Annotations.Events.ANNOTATION_DELETED, () => {
            this.setAnnotationChangeData(annotations);
            this.hasComments = annotations.countAnnotations() > 0;
        });
        annotations.on(loop.Annotations.Events.ANNOTATION_RESOLVED, () => {
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.ANNOTATION_SELECTED, (anannotationId: any) => {
            this.moveBubbleAnnotationToScroll(anannotationId.annotation.id);
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.ANNOTATION_UPDATED, (anannotationId: any) => {
            this.setAnnotationChangeData(annotations);
            this.hasComments = annotations.countAnnotations() > 0;
        });
        annotations.on(loop.Annotations.Events.ATTRIBUTE_CHANGED, (annotation) => {
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.COMMENT_ADDED, (annotation) => {
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.COMMENT_CHANGED, (annotation: any, comment: any) => {
            this.setAnnotationChangeData(annotations);
            this.hasComments = annotations.countAnnotations() > 0;
        });
        annotations.on(loop.Annotations.Events.COMMENT_DELETED, (annotation: any, comment: any) => {
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.COMMENT_SELECTED, (annotation: any, comment: any) => {
            this.moveBubbleAnnotationToScroll(annotation.annotation.id);
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.DONE_EDITING, (annotation: any, comment: any) => {
            this.hasComments = annotations.countAnnotations() > 0;
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.ENABLED_CHANGED, (annotation: any, comment: any) => {
            this.setAnnotationChangeData(annotations);
        });
        annotations.on(loop.Annotations.Events.RELOAD, (annotation: any, comment: any) => {
            this.setAnnotationChangeData(annotations);
        });

        annotations.setDefaultAvatar('plugins/lance-tmce/avatars/default.png');

        lance.maximize = {
            uiSelector: '#comments' // an element with the id "sidebar"

        };
    }

    private moveBubbleAnnotationToScroll(idAnnotation: string) {

        const annotationInEditor = this.editorInstance.document.findOne('#' + idAnnotation);  // Buble goes to here
        const bubble = window.document.querySelector('div.annotation-ui-wrapper[data-selected="true"]');

        if (!bubble || !annotationInEditor) {
            return;
        }

        const oldDisplayInBubble = this.showHideElementsService.hideElement(bubble);
        annotationInEditor.$.scrollIntoViewIfNeeded({ block: 'center' }); // Using this instead ckeditor to get center.

        let currentStyle = bubble.getAttribute('style');
        const yPosition = annotationInEditor.$.getBoundingClientRect().top + 48;

        const regExp = new RegExp('top:\\s(-)?(\\d)+px;');
        const newTop = 'top: ' + yPosition + 'px;';
        if (currentStyle.search(regExp)) {
            currentStyle = currentStyle.replace(regExp, newTop);
        } else {
            currentStyle = currentStyle + newTop;
        }

        bubble.setAttribute('style', currentStyle);

        this.showHideElementsService.restoreElement(bubble, oldDisplayInBubble);
    }

    private setAnnotationChangeData(annotations: any) {
        setTimeout(function() {
            this.sendDataChange(null);
        }.bind(this), 1);
    }

    private getModalConfig(): GenericDialogConfig {
        const config = this.genericDialogService.getDefaultDialogConfig();
        config.panelClass = 'cdk-overlay-fullscreen';
        config.backdropClass = 'cdk-overlay-backdrop-white';
        config.width = '50vw';
        config.height = '80vh';

        return config;
    }

    private getRolFromSignature(signature: SignatureModel, currentSignaturesNumber: number): { role: string, showRole: boolean; } {
        let showRole = true;
        let role = signature.role;

        if (signature.role === '') {
            showRole = false;
            role = $localize`:@@Firmante:Firmante ` + (currentSignaturesNumber + 1);
        }

        return { role, showRole };
    }

    private insertNewSignature(result: SignatureModel, editor: any) {
        if (!result) {
            return;
        }

        // Looking for a position editable to insert signature
        const FIRST_RANGE = 0;
        let sel = editor.getSelection();
        let range = sel.getRanges()[FIRST_RANGE];
        range.moveToClosestEditablePosition();
        const numberSignaturesBeforeInserting = this.getNumSignatures();
        const rol = this.getRolFromSignature(result, numberSignaturesBeforeInserting);

        const widget = editor.widgets.registered.insertSignature.template;

        widget.source = this.addSignatureTemplate(rol.role, rol.showRole);
        editor.execCommand('insertSignature'); // this insert the signature while instancing the widget

        this.currentSignatureNumber = this.getNumSignatures();
        if (numberSignaturesBeforeInserting > 0) {
            this.recalculateRoles();
        }

        // Looking for a position editable for cursor after insert signature
        sel = editor.getSelection();
        range = sel.getRanges()[FIRST_RANGE];

        range.moveToClosestEditablePosition();
    }

    private insertNewClause(clause: ClauseDTO, editor: any) {
        if (!clause) {
            return;
        }
        const textClause = clause.description.replace(/(\r\n|\n|\r|' ')/gm, '').trim();

        if (!this.isDocumentSameOrganization) {
            editor.insertHtml(textClause);
        } else {
            const hashDescription = this.md5.start().appendStr(textClause).end();

            const widget = editor.widgets.registered.insertClause.template;
            this.currentClauseId = 'id_' + clause.id;
            widget.source = this.getClauseTemplate(this.currentClauseId, hashDescription, textClause);
            editor.execCommand('insertClause'); // this insert the clause instancing the widget
            editor.moveToPosition(editor.widgets.focused.element, CKEDITOR.SELECTION_NONE);
        }
    }

    private insertSeveralClauses(result: any, editor: any) {
        if (!result) {
            return;
        }

        for (const clause of result) {
            this.insertNewClause(clause, editor);
            const range = editor.createRange();
            if (editor.widgets.focused !== undefined && editor.widgets.focused !== null) {
                range.moveToPosition(editor.widgets.focused.element, CKEDITOR.POSITION_AFTER_END);
                range.moveToPosition(editor.widgets.focused.element, CKEDITOR.SELECTION_NONE);
            }
        }
    }

    private setInteractiveFormsAsReadOnly(editorInstance: any) {
        if (editorInstance && this.setReadOnlyInInteractiveForms) {
            this.ckeditorFormsUtilsService.configureInteractiveFormsAsReadOnly(editorInstance);
        }
    }

    private setInputsInClausesAsReadOnly(editorInstance: any) {
        if (editorInstance && this.setReadOnlyInputsInClauses) {
            this.ckeditorFormsUtilsService.configureInputsAndSelectedInClausesAsDisabled(editorInstance);
        }
    }

    private treatAbstracts(editorInstance: any) {

        const abstractInDocument = editorInstance.document?.$.querySelectorAll('div.fAb');

        for (let i = 0; i < abstractInDocument.length; i++) {
            const coAbstract = abstractInDocument[i] as HTMLElement;

            if (coAbstract.parentElement.tagName === 'LI') {
                this.treatAbstractInList(coAbstract, editorInstance);
                continue;
            }

            this.treatConsecutiveAbstracts(coAbstract);
        }

        const fAbGroupElements = editorInstance.document?.$.querySelectorAll('div.fAbGroup');

        for (let i = 0; i < fAbGroupElements.length; i++) {
            const element = fAbGroupElements[i] as HTMLElement;
            element.classList.remove('fAbGroup');
            element.classList.add('fAb');

            if (!element.nextElementSibling) {
                this.insertJumpAfterElement(element);
            }
        }
    }

    private insertJumpAfterElement(element: HTMLElement) {
        const parent = element.parentElement;
        const enterDiv = document.createElement('div');
        enterDiv.innerHTML = '&nbsp';
        parent.appendChild(enterDiv);
    }

    private treatAbstractInList(coAbstract: HTMLElement, editorInstance: any) {
        const li = coAbstract.parentElement;
        const list = li.parentElement;
        li.textContent = coAbstract.textContent;
        coAbstract.remove();

        if (list.parentElement.classList.contains('fAbGroup')) {
            list.parentNode.appendChild(list);
        } else {
            const parentDiv = editorInstance.document?.$.createElement('div');
            list.parentElement.insertBefore(parentDiv, list);
            parentDiv.classList.add('fAbGroup');
            parentDiv.appendChild(list);
        }
    }

    private treatConsecutiveAbstracts(coAbstract: HTMLElement) {
        const nextNode = coAbstract.nextElementSibling;
        const previousNode = coAbstract.previousElementSibling;

        if (nextNode && coAbstract.nextElementSibling?.classList &&
            coAbstract.nextElementSibling?.classList?.contains('fAbGroup')) {
            nextNode.insertBefore(coAbstract, nextNode.firstChild);
        } else if (previousNode && coAbstract.previousElementSibling?.classList
            && coAbstract.previousElementSibling?.classList?.contains('fAbGroup')) {
            previousNode.appendChild(coAbstract);
        } else {
            coAbstract.classList.add('fAbGroup');
        }

        coAbstract.classList.remove('fAb');
    }

    private setInputsEditableOrReadOnly(editorInstance: any) {
        if (editorInstance && this.activateTextAndSelectInputs) {
            this.ckeditorFormsUtilsService.configureInputsTextAndSelectEditablesDocument(editorInstance.document.$, editorInstance,
                this.sendDataChangeWithEditorInstance, this.changeContent, this.documentPartsForEditor);
        } else if (!this.editorReadOnly) {
            this.ckeditorFormsUtilsService
                .configureInputsAndSelectedEditables(editorInstance, this.sendDataChangeWithEditorInstance,
                    this.changeContent, this.documentPartsForEditor);
        } else {
            this.ckeditorFormsUtilsService.configureInputsAndSelectedAsReadOnly(editorInstance);
        }
    }

    private addPlumisGroup(id: string, editorInstance: any) {
        const elementToCopy = editorInstance.document.$.querySelector('#' + id + '_1');
        const parent = elementToCopy.parentElement;
        if (parent.children.length >= 10) {
            this.showMessage(this.maximumReached);
            return;
        }
        const countNext = parent.children.length + 1;
        const cloneNode = this.cloneNodeWithDiferentId(id, elementToCopy, countNext);
        parent.appendChild(cloneNode);
        this.consolideCurrentDataInEditor(editorInstance);
        this.sendDataChange(null);
    }

    private removePlumisGroup(id: string, editorInstance: any) {
        const divPlumis = editorInstance.document.findOne('#' + id + '_0');
        if (divPlumis.getChildCount() <= 1) {
            this.showMessage(this.minimumReached);
            return;
        }

        const lastChildToRemove = divPlumis.getChild(divPlumis.getChildCount() - 1);
        lastChildToRemove.remove();
        this.consolideCurrentDataInEditor(editorInstance);
        this.sendDataChange(null);
    }

    private consolideCurrentDataInEditor(editorInstance: any) {
        const data = editorInstance.getSnapshot();
        editorInstance.loadSnapshot(data);
    }

    private cloneNodeWithDiferentId(id: string, nodo: HTMLElement, count: number) {
        const clon = nodo.cloneNode(true) as HTMLElement;
        clon.id = id + '_' + count;
        clon.querySelectorAll('[id]').forEach((child: any) => {
            child.id = child.id + '_' + count;
            child.name = child.name + '_' + count;
            child.setAttribute('data-cke-saved-name', child.name);
        });

        return clon;
    }

    private getNumSignatures(): number {
        if (!this.editorInstance) {
            return 0;
        }

        if (!this.editorInstance.document) {
            return 0;
        }
        const signatureList = this.editorInstance.document?.$.querySelectorAll('.' + this.SIGNATURE_CLASS);
        return signatureList.length;
    }

    private updateComments() {
        const lance = this.editorInstance.plugins.lance?.findPlugin(this.editorInstance);
        if (!lance) {
            return;
        }

        const annotations = lance.getAnnotations();
        this.setAnnotationChangeData(annotations);
        this.hasComments = annotations.countAnnotations() > 0;
    }

    private changeRepitedRolesToDefault(evt: any): void {
        const toPaste = evt.data.dataTransfer.getData('text/html');
        const roles = this.getCurrentRoles();

        const htmlToSearch = document.createElement('div');
        htmlToSearch.innerHTML = toPaste;
        const signatureList = htmlToSearch.querySelectorAll('.' + this.SIGNATURE_CLASS);

        let hasChanged = false;
        signatureList.forEach((signature) => {
            const role: string = signature.getAttribute(this.ATTRIBUTE_ROLE_IS_STORED);

            if (roles.find((element) => element !== role)) {
                this.setSignatureToDefault(signature);
                hasChanged = true;
            }
        });

        if (hasChanged) {
            evt.data.dataValue = htmlToSearch.innerHTML;
        }
    }

    private setSignatureToDefault(signature: Element): void {
        const defaultNumberSignature = 1;
        const defaultRoleId = this.SIGNATURE_ID_BASE + defaultNumberSignature;
        const defaultRole = $localize`:@@Firmante:Firmante ` + defaultNumberSignature;
        signature.setAttribute('id', defaultRoleId);
        signature.setAttribute(this.ATTRIBUTE_ROLE_IS_STORED, defaultRole);
        signature.innerHTML = this.addSignatureTemplateContent(defaultRole, '');
    }

    private lockSignatureEdition(): void {
        this.signatureEditionLock++;
    }

    private unlockSignatureEdition(): void {
        this.signatureEditionLock--;

        if (this.signatureEditionLock < 0) {
            this.signatureEditionLock = 0;
        }
    }

    private isSignatureditionLocked(): boolean {
        return this.signatureEditionLock > 0;
    }

    private cargaCheck() {
        const jquery = (window as any).$;
        const documentInstance = this.editorInstance.document?.$;
        if (jquery(documentInstance).find('.FO:not(.smart-forms) .rBox').length || jquery(documentInstance).find('.FO:not(.smart-forms) .opt').length) {
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.match(/Trident\/7\./)) {
                jquery(documentInstance).find('div.opt > input[type=checkbox]')
                    .after('<p class=\'FFox_IE\'>Activa el check si deseas incluir este párrafo</p>');
            }

            jquery(documentInstance).find('.opt > a[title=\'Eliminar un campo del formulario\']').parent().addClass('xsel');
            jquery(documentInstance).find('.rSelect').prev('input').addClass('block');
        }
    }

    private disableConfigModalPlumis() {
        const jquery = (window as any).$;

        if (!this.editorInstance.document) {
            return;
        }

        const documentInstance = this.editorInstance.document.$;

        jquery(documentInstance).find('a[shape=rect]').dblclick((e) => {
            e.stopPropagation();
        });
    }

    private changeTooltipFromButton() {
        const jquery = (window as any).$;

        jquery('.cke_button__textfield_label').text($localize`:@@InsertarCampoParaRellenar:Insertar campo para rellenar`);
        jquery('.cke_button__textfield').attr('title', $localize`:@@InsertarCampoParaRellenar:Insertar campo para rellenar`);

        jquery('.cke_button__checkbox_label').text($localize`:@@InsertarTextoOpcional:Insertar texto opcional`);
        jquery('.cke_button__checkbox').attr('title', $localize`:@@InsertarTextoOpcional:Insertar texto opcional`);

        jquery('.cke_button__radio_label').text($localize`:@@InsertarOpciones:Insertar párrafos alternativos`);
        jquery('.cke_button__radio').attr('title', $localize`:@@InsertarOpciones:Insertar párrafos alternativos`);

        jquery('.cke_button__select_label').text($localize`:@@InsertarDesplegable:Insertar desplegable`);
        jquery('.cke_button__select').attr('title', $localize`:@@InsertarDesplegable:Insertar desplegable`);

        jquery('.cke_button__copyformatting').attr('title', $localize`:@@CopiarFormato:Copiar formato`);
    }

    private commentFunction() {
        const documentInstance = this.editorInstance.document?.$;
        this.htmlEventsService.eventComment(documentInstance, 'click');
    }

}

function getRandomNumber() {
    return Math.floor(Math.random() * (100000000000000 - 100 + 1) + 100);
}

function getRandomId() {
    return 'I' + getRandomNumber();
}

function deleteAndNewIdsHtmlStructure(content: string, htmlStructure: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    const elements = tempDiv.querySelectorAll(htmlStructure);
    elements.forEach((element) => {
        if (element.getAttribute('id')) {
            element.removeAttribute('id');
        }
        const newIdValue = getRandomId();
        element.setAttribute('id', newIdValue);
    });

    return tempDiv.innerHTML;
}
