import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class CkeditorFormatsService {

    public addPluginFormats() {
        delete CKEDITOR.plugins.registered.format;

        this.configureFormats();

        if (!(CKEDITOR.config.extraPlugins as string).includes('format')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('format,');
        }
    }

    public configureFormats() {
        CKEDITOR.plugins.add('format', {
            requires: 'richcombo',
            // jscs:disable maximumLineLength
            lang: 'af,ar,az,bg,bn,bs,ca,cs,cy,da,de,de-ch,el,en,en-au,en-ca,en-gb,eo,es,es-mx,et,eu,fa,fi,fo,fr,fr-ca,gl,gu,he,hi,hr,hu,id,is,it,ja,ka,km,ko,ku,lt,lv,mk,mn,ms,nb,nl,no,oc,pl,pt,pt-br,ro,ru,si,sk,sl,sq,sr,sr-latn,sv,th,tr,tt,ug,uk,vi,zh,zh-cn', // %REMOVE_LINE_CORE%
            // jscs:enable maximumLineLength
            init: function (editor) {
                if (editor.blockless)
                    return;

                var config = editor.config,
                    lang = editor.lang.format;

                // Gets the list of tags from the settings.
                var tags = config.format_tags.split(';');

                // Create style objects for all defined styles.
                var styles = {},
                    stylesCount = 0,
                    allowedContent = [];
                for (var i = 0; i < tags.length; i++) {
                    var tag = tags[i];
                    var style = new CKEDITOR.style(config['format_' + tag]);
                    if (!editor.filter.customConfig || editor.filter.check(style)) {
                        stylesCount++;
                        styles[tag] = style;
                        styles[tag]._.enterMode = editor.config.enterMode;
                        allowedContent.push(style);
                    }
                }

                // Hide entire combo when all formats are rejected.
                if (stylesCount === 0)
                    return;

                editor.ui.addRichCombo('Format', {
                    label: lang.label,
                    title: lang.panelTitle,
                    toolbar: 'styles,20',
                    allowedContent: allowedContent,

                    panel: {
                        css: [CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
                        multiSelect: false,
                        attributes: { 'aria-label': lang.panelTitle }
                    },

                    init: function () {
                        this.startGroup(lang.panelTitle);

                        for (var tag in styles) {
                            var label = lang['tag_' + tag];

                            // Add the tag entry to the panel list.
                            this.add(tag, styles[tag].buildPreview(label), label);
                        }
                    },

                    onClick: function (value) {
                        editor.focus();
                        editor.fire('saveSnapshot');

                        var style = styles[value],
                            elementPath = editor.elementPath();

                        // (#3649)
                        editor.fire('stylesRemove', { type: CKEDITOR.STYLE_BLOCK });

                        // Always apply style, do not allow to toggle it by clicking on corresponding list item (#584).
                        if (!style.checkActive(elementPath, editor)) {
                            editor.applyStyle(style);
                        }

                        // Save the undo snapshot after all changes are affected. (https://dev.ckeditor.com/ticket/4899)
                        setTimeout(function () {
                            editor.fire('saveSnapshot');
                        }, 0);
                    },

                    onRender: function () {
                        editor.on('selectionChange', function (ev) {
                            var currentTag = this.getValue(),
                                elementPath = ev.data.path;

                            this.refresh();

                            for (var tag in styles) {
                                if (styles[tag].checkActive(elementPath, editor)) {
                                    if (tag != currentTag)
                                        this.setValue(tag, editor.lang.format['tag_' + tag]);
                                    return;
                                }
                            }

                            // If no styles match, just empty it.
                            this.setValue('');

                        }, this);
                    },

                    onOpen: function () {
                        this.showAll();
                        for (var name in styles) {
                            var style = styles[name];

                            // Check if that style is enabled in activeFilter.
                            if (!editor.activeFilter.check(style))
                                this.hideItem(name);

                        }
                    },

                    refresh: function () {
                        var elementPath = editor.elementPath();

                        if (!elementPath)
                            return;

                        // Check if element path contains 'p' element.
                        if (!elementPath.isContextFor('p')) {
                            this.setState(CKEDITOR.TRISTATE_DISABLED);
                            return;
                        }

                        // Check if there is any available style.
                        for (var name in styles) {
                            if (editor.activeFilter.check(styles[name]))
                                return;
                        }
                        this.setState(CKEDITOR.TRISTATE_DISABLED);
                    }
                });
            }
        });
        CKEDITOR.config.format_tags = 'p;h1;h2;h3;h4;h5;h6;pre;address;div';
        CKEDITOR.config.format_p = { element: 'p', attributes: { 'class': 'normalPara' } };
        CKEDITOR.config.format_div = { element: 'div' };
        CKEDITOR.config.format_pre = { element: 'pre' };
        CKEDITOR.config.format_address = { element: 'address' };
        CKEDITOR.config.format_h1 = { element: 'h1', attributes: { 'class': 'd1' } };
        CKEDITOR.config.format_h2 = { element: 'h2', attributes: { 'class': 'd2' } };
        CKEDITOR.config.format_h3 = { element: 'h3', attributes: { 'class': 'd3' } };
        CKEDITOR.config.format_h4 = { element: 'h4', attributes: { 'class': 'd4' } };
        CKEDITOR.config.format_h5 = { element: 'h5', attributes: { 'class': 'd5' } };
        CKEDITOR.config.format_h6 = { element: 'h6', attributes: { 'class': 'd6' } };
    }
}
