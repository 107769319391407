import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClauseFolderDTO, ClauseFolderService, ClauseService, ErrorClauseFolderOperationDto, UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { IClausesFolderTreeService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-tree.service.interface';
import { IClausesFolderService } from 'src/app/core/shared/services/clauses/clauses-folder/clauses-folder.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeAction } from 'src/app/shared/components/ctbox-tree/models/node-tree-action.model';
import { NodeTreeUserAction } from 'src/app/shared/components/ctbox-tree/enums/node-tree-user-action.enum';
import { ClauseFolderPostOperationAction } from 'src/app/pages/standard/clauses-library/clause-folder/enums/clause-folder-post-operation-actions.enums';
import { NIL as NIL_UUID } from 'uuid';

@Component({
    selector: 'app-clause-folder',
    templateUrl: './clause-folder.component.html'
})
export class ClauseFolderComponent implements OnInit, OnDestroy {

    @Input() rootActions: NodeTreeActionType[] = [NodeTreeActionType.Create, NodeTreeActionType.Selected];
    @Input() nodeActions: NodeTreeActionType[] = [NodeTreeActionType.Create, NodeTreeActionType.Selected, NodeTreeActionType.Rename];

    @Output() selectedNodeIdTree = new EventEmitter<string>();

    public clauseFolderTree: FileNode[];
    public clauseTreeSelectionModel: SelectionModel<string>;
    public isTreeLocked: boolean;
    public actions: NodeTreeUserAction[] = [NodeTreeUserAction.Menu];
    public initialNodes: string[] = [];
    public currentFolderId: string;

    private isValidSubscription = new BehaviorSubject<boolean>(false);
    private userInfo: UserInfoDTO;
    private userInfoSubscription: Subscription;
    private clauseTreeSelectionModelSubscription: Subscription;
    private currentFolderIdSubscription: Subscription;
    private emptyClauseMessage =
        $localize`:@@NuevaCarpetaErrorNombreVacioMensaje:Introduce un nombre para la carpeta, por favor.`;

    constructor(
        private loginService: AuthorizeService,
        private genericDialogService: GenericDialogService,
        private apiClauseFolder: ClauseFolderService,
        private clausesFolderService: IClausesFolderService,
        @Inject('IClausesFolderTreeService') private readonly clausesFolderTreeService: IClausesFolderTreeService,
        private clauseService: ClauseService) { }

    public ngOnInit(): void {
        this.clauseTreeSelectionModelSubscription = this.clausesFolderTreeService.getCurrentExpansionModel()
            .subscribe(selectionModel => this.clauseTreeSelectionModel = selectionModel);

        this.userInfoSubscription = this.loginService.getUserInfo().subscribe(userInfo => {
            this.userInfo = userInfo;
            this.loadFolderTree();
        });

        this.currentFolderIdSubscription = this.clausesFolderService.getCurrentFolderId().subscribe((currentFolderId: string) => {
            this.currentFolderId = currentFolderId;
        });
    }

    public ngOnDestroy(): void {
        if (this.currentFolderIdSubscription) {
            this.currentFolderIdSubscription.unsubscribe();
        }

        if (this.clauseTreeSelectionModelSubscription) {
            this.clauseTreeSelectionModelSubscription.unsubscribe();
        }

        if (this.userInfoSubscription) {
            this.userInfoSubscription.unsubscribe();
        }
    }

    public doActionFromNodeEvent(nodeEvent: NodeTreeAction) {
        switch (nodeEvent.typeEvent) {
            case NodeTreeActionType.Create:
                this.createFolder(nodeEvent);
                break;
            case NodeTreeActionType.Delete:
                this.deleteFolder(nodeEvent);
                break;
            case NodeTreeActionType.Selected:
                this.folderSelected(nodeEvent);
                break;
            case NodeTreeActionType.Rename:
                this.renameFolder(nodeEvent);
                break;
            case NodeTreeActionType.Move:
                this.moveFolder(nodeEvent);
                break;
            case NodeTreeActionType.MoveFromOutside:
                this.moveClauseToFolder(nodeEvent);
                break;
        }
    }

    private moveClauseToFolder(nodeAction: NodeTreeAction) {
        const node = nodeAction.node;
        const templateIdToMove = nodeAction.additionalParam.droppedObjectId;

        this.clauseService.clausesIdFolderFolderIdPut(templateIdToMove, node.id).subscribe((data) => {
            const message = $localize`:@@MoverClausulaACarpetaMensaje:La cláusula se movió a la carpeta: ` + node.value;
            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                this.doActionAfter(ClauseFolderPostOperationAction.NoAction, nodeAction);
                this.selectNode(node.id, node.value);
            });
        }, (error: any) => {
            const message = $localize`:@@MoverClausulaACarpetaErrorMensaje:Se ha producido un error al mover la cláusula a la carpeta.`;
            this.genericDialogService.showMessage(message);
        });
    }

    private createFolder(nodeAction: NodeTreeAction): void {
        if (nodeAction.node.value.trim() === '') {
            this.genericDialogService.showMessage(this.emptyClauseMessage).afterClosed();
            return;
        }

        const folder: ClauseFolderDTO = {
            id: crypto.randomUUID(),
            name: nodeAction.node.value,
            clauseFolderParentId: nodeAction.node.parentId === this.clausesFolderTreeService.getRootNodeId() ?
            NIL_UUID : nodeAction.node.parentId,
        };

        this.apiClauseFolder.clauseFoldersPost(folder).subscribe((folderCreated: ClauseFolderDTO) => {
            nodeAction.node.id = folderCreated.id;
            this.loadFolderTree();
            this.sendTreeOperationCallbackSuccessful(nodeAction);
        }, (error: any) => {
            this.manageError(error).then((actionAfter: ClauseFolderPostOperationAction) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private deleteFolder(nodeAction: NodeTreeAction) {
        const folderName = nodeAction.node.value;
        const message = $localize`:@@EliminaCarpetaClausulaConContenidoMensaje:Se eliminará la carpeta «${folderName}» y todo su contenido ¿deseas continuar?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }
            this.apiClauseFolder.clauseFoldersDelete(nodeAction.node.id).subscribe(() => {
                this.loadFolderTree();
                this.sendTreeOperationCallbackSuccessful(nodeAction);
                const parentId = nodeAction.node.parentId;
                const parentName = this.clauseFolderTree.find(f => f.id === parentId)?.value;
                this.selectNode(parentId, parentName);

            }, error => {
                this.manageError(error).then((actionAfter: ClauseFolderPostOperationAction) => {
                    this.doActionAfter(actionAfter, nodeAction);
                });
            });
        });
    }

    private renameFolder(nodeAction: NodeTreeAction): void {
        const node = nodeAction.node;
        const patchOperations = this.clausesFolderService.getOperationReplaceClauseFolder('name', node.value);
        const nodeNewName = node.value;
        this.apiClauseFolder.clauseFoldersClauseFolderIdPatch(node.id, patchOperations).subscribe(() => {
            nodeAction.node.value = nodeNewName;
            this.sendTreeOperationCallbackSuccessful(nodeAction);
        }, (error: any) => {
            this.manageError(error).then((actionAfter: ClauseFolderPostOperationAction) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private moveFolder(nodeAction: NodeTreeAction): void {
        const node = nodeAction.node;
        const nodeDestination = nodeAction.additionalParam.destinationNode;

        if (node.parentId === node.id || nodeDestination.id === node.id || node.parentId === nodeDestination.id ||
            nodeDestination.parentId === node.id) {
            return;
        }

        const newParentId = nodeDestination.id !== this.clausesFolderTreeService.getRootNodeId() ? nodeDestination.id : null;
        const operations = this.clausesFolderService.getOperationReplaceClauseFolder('clauseFolderParentId', newParentId);

        this.apiClauseFolder.clauseFoldersClauseFolderIdPatch(node.id, operations).subscribe((data) => {
            this.sendTreeOperationCallbackSuccessful(nodeAction);
            nodeAction.node.parentId = data.clauseFolderParentId;
            this.loadFolderTree();
        }, (error: any) => {
            this.manageError(error).then((actionAfter: ClauseFolderPostOperationAction) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    private loadFolderTree(): void {
        if (!this.userInfo) {
            return;
        }

        this.apiClauseFolder.clauseFoldersGet().subscribe((folders: ClauseFolderDTO[]) => {
            this.clauseFolderTree = this.clausesFolderTreeService.createTreeFolder(folders, this.rootActions, this.nodeActions);
            this.initialNodes.push(this.clausesFolderTreeService.getRootNodeId());
        });
    }

    private sendTreeOperationCallbackFail(response: NodeTreeAction) {
        if (!response.failAction) {
            return;
        }

        response.failAction(response.node);
    }

    private sendTreeOperationCallbackSuccessful(response: NodeTreeAction) {
        if (!response.successfulAction) {
            return;
        }

        response.successfulAction(response.node);
    }

    private manageError(error: any): Promise<ClauseFolderPostOperationAction> {
        const promiseError = new Promise<ClauseFolderPostOperationAction>((resolve) => {
            let actionAfter = ClauseFolderPostOperationAction.NoAction;

            const genericErrorMessage = $localize`:@@MoverClausulaACarpetaErrorMensaje:Se ha producido un error al mover la cláusula a la carpeta.`;

            this.apiClauseFolder.clauseFoldersErrorDescriptionGet().subscribe((errorDescriptions: ErrorClauseFolderOperationDto) => {
                let message = genericErrorMessage;

                if (error.error?.TemplateFolder && error.error.TemplateFolder.length > 0) {
                    const errorDescription = error.error?.TemplateFolder[0];
                    if (errorDescription === errorDescriptions.movementNotAllowedMessage) {
                        message = genericErrorMessage;
                    } else if (errorDescription === errorDescriptions.accessNotAllowed) {
                        message = $localize`:@@ClausulasNoTienesPermisoEnEstaCarpeta:No tienes permisos para modificar esta carpeta.`;
                    } else if (errorDescription === errorDescriptions.notFound) {
                        message = $localize`:@@MoverClausulaACarpetaErrorFaltaPermisos:No tienes permisos para mover esta cláusula.`;
                    } else if (errorDescription === errorDescriptions.folderLocked) {
                        this.lockTree();
                        message = $localize`:@@ClausulasOperacionFallidaArbolBloqueado:No se puede realizar la operación porque el árbol de carpetas está bloqueado en este momento.`;
                        actionAfter = ClauseFolderPostOperationAction.Cancel;
                    } else if (errorDescription === errorDescriptions.folderOutdated) {
                        message = $localize`:@@ClausulasArbolDeCarpetasNoSincronizadoMensaje:El árbol de carpetas no está sincronizado. Se refrescará automáticamente.`;
                        actionAfter = ClauseFolderPostOperationAction.Refresh;
                    }

                } else if (error.error?.Name && error.error.Name.length > 0) {
                    const errorDescription = error.error.Name[0];

                    if (errorDescription === errorDescriptions.nameUsedAlreadyMessage) {
                        message = $localize`:@@ClausulasNuevaCarpetaErrorMismoNombreAlGuardarMensaje:Ya existe otra carpeta con el mismo nombre. Introduce otro nombre, por favor.`;
                        // TODO falta que el back nos devuelva este codigo
                    }
                }
                this.genericDialogService.showMessage(message).afterClosed().subscribe(() => resolve(actionAfter));
            }, () => {
                const message = genericErrorMessage;
                this.genericDialogService.showMessage(message).afterClosed().subscribe(() => resolve(actionAfter));
            });
        });
        return promiseError;
    }

    private doActionAfter(actionAfter: ClauseFolderPostOperationAction, nodeAction: NodeTreeAction) {
        switch (actionAfter) {
            case ClauseFolderPostOperationAction.NoAction:
                this.sendTreeOperationCallbackFail(nodeAction);
                break;
            case ClauseFolderPostOperationAction.Cancel:
            case ClauseFolderPostOperationAction.Refresh:
                this.loadFolderTree();
                break;
        }
    }

    private lockTree() {
        this.isTreeLocked = true;
    }

    private folderSelected(nodeAction: NodeTreeAction) {
        const node = nodeAction.node;
        this.selectNode(node.id, node.value);
    }

    private selectNode(nodeId: string, nodeValue: string) {
        this.clausesFolderService.setCurrentFolderId(nodeId);
        this.clausesFolderService.setCurrentFolderName(nodeValue);
        this.sendIsValidState(nodeId);

        this.selectedNodeIdTree.emit(nodeId);
    }

    private sendIsValidState(nodeId: string) {
        this.isValidSubscription.next(this.clausesFolderService.isCurrentFolderValid(nodeId));
    }

}
