/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErrorTemplateOperationDTO { 
    readonly nameUsedAndCanNotBeOverwrittenMessage?: string;
    readonly nameUsedAndCanBeOverwrittenMessage?: string;
    readonly userHasNoPermissionsForOperationMessage?: string;
    readonly isNotFoundMessage?: string;
    readonly isMovementForbiddenMessage?: string;
    readonly genericErrorMessage?: string;
}