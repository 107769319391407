export const permissionsEditDeleteConfiguration =  {
    permissionsCreatorRevisorCanDeleteUser : {
        edit: {
            first: "owner", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          },
          delete: {
            first: "user",// legal values: "opener","none", "owner", "user", "any"
            last: "user", // default value
            default: "user" // default value
          }
    },
    permissionsRegisteredRevisorCanDeleteUser : {
        edit: {
            first: "owner", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          },
          delete: {
            first: "user",// legal values: "opener","none", "owner", "user", "any"
            last: "user", // default value
            default: "user" // default value
          }
    },
    permissionsRegisteredRevisorCannotDeleteUser : {
        edit: {
            first: "owner", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          },
          delete: {
            first: "owner",// legal values: "opener","none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          }
    },
    permissionsRegisteredNoRevisorUser : {
        edit: {
            first: "none", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "none", // default value
            default: "none" // default value
          },
          delete: {
            first: "none",// legal values: "opener","none", "owner", "user", "any"
            last: "none", // default value
            default: "none" // default value
          }
    },
    permissionsGuestNoRevisorUser : {
        edit: {
            first: "none", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "none", // default value
            default: "none" // default value
          },
          delete: {
            first: "none",// legal values: "opener","none", "owner", "user", "any"
            last: "none", // default value
            default: "none" // default value
          }
    },
    permissionsGuestRevisorCanDeleteUser : {
        edit: {
            first: "user", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "user", // default value
            default: "user" // default value
          },
          delete: {
            first: "user",// legal values: "opener","none", "owner", "user", "any"
            last: "user", // default value
            default: "user" // default value
          }
    },

    permissionsGuestRevisorCannotDeleteUser : {
        edit: {
            first: "owner", // default value, legal values: "opener", "none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          },
          delete: {
            first: "owner",// legal values: "opener","none", "owner", "user", "any"
            last: "owner", // default value
            default: "owner" // default value
          }
    }
}
