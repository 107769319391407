/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClauseDTO } from '../model/clauseDTO';
import { ErrorClauseOperationDTO } from '../model/errorClauseOperationDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClauseService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clausesDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clausesDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clausesDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/clauses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorClauseOperationDTO>;
    public clausesErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorClauseOperationDTO>>;
    public clausesErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorClauseOperationDTO>>;
    public clausesErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorClauseOperationDTO>('get',`${this.basePath}/clauses/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesFolderFolderIdGet(folderId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseDTO>>;
    public clausesFolderFolderIdGet(folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseDTO>>>;
    public clausesFolderFolderIdGet(folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseDTO>>>;
    public clausesFolderFolderIdGet(folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling clausesFolderFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseDTO>>('get',`${this.basePath}/clauses/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesFolderGet(folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseDTO>>;
    public clausesFolderGet(folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseDTO>>>;
    public clausesFolderGet(folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseDTO>>>;
    public clausesFolderGet(folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseDTO>>('get',`${this.basePath}/clauses/folder`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseDTO>>;
    public clausesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseDTO>>>;
    public clausesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseDTO>>>;
    public clausesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseDTO>>('get',`${this.basePath}/clauses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesIdFolderFolderIdPut(id: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clausesIdFolderFolderIdPut.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling clausesIdFolderFolderIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseDTO>('put',`${this.basePath}/clauses/${encodeURIComponent(String(id))}/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clausesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseDTO>('get',`${this.basePath}/clauses/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesIdToRootFolderPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesIdToRootFolderPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesIdToRootFolderPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesIdToRootFolderPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clausesIdToRootFolderPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseDTO>('put',`${this.basePath}/clauses/${encodeURIComponent(String(id))}/to-root-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesPost(body?: ClauseDTO, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesPost(body?: ClauseDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesPost(body?: ClauseDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesPost(body?: ClauseDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseDTO>('post',`${this.basePath}/clauses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesPut(body?: ClauseDTO, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesPut(body?: ClauseDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesPut(body?: ClauseDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesPut(body?: ClauseDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseDTO>('put',`${this.basePath}/clauses`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesSaveAndReviewedLldPendingPut(body?: ClauseDTO, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesSaveAndReviewedLldPendingPut(body?: ClauseDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesSaveAndReviewedLldPendingPut(body?: ClauseDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesSaveAndReviewedLldPendingPut(body?: ClauseDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseDTO>('put',`${this.basePath}/clauses/save-and-reviewed-lld-pending`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param searchTerm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesSearchNameNameDescriptionDescriptionGet(searchTerm: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseDTO>>;
    public clausesSearchNameNameDescriptionDescriptionGet(searchTerm: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseDTO>>>;
    public clausesSearchNameNameDescriptionDescriptionGet(searchTerm: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseDTO>>>;
    public clausesSearchNameNameDescriptionDescriptionGet(searchTerm: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (searchTerm === null || searchTerm === undefined) {
            throw new Error('Required parameter name was null or undefined when calling clausesSearchNameNameDescriptionDescriptionGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseDTO>>('get',`${this.basePath}/clauses/search/${searchTerm}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling clausesSearchNameNameFolderIdFolderIdGet.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling clausesSearchNameNameFolderIdFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseDTO>('get',`${this.basePath}/clauses/search/name/${encodeURIComponent(String(name))}/folderId/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clausesSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseDTO>;
    public clausesSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseDTO>>;
    public clausesSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseDTO>>;
    public clausesSearchNameNameFolderIdGet(name: string, folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling clausesSearchNameNameFolderIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseDTO>('get',`${this.basePath}/clauses/search/name/${encodeURIComponent(String(name))}/folderId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
