import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { ClauseDTO, UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { ManagerClauseService } from 'src/app/api/api/managerClause.service';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { tap, takeUntil } from 'rxjs/operators';
import { IFileStateService } from 'src/app/core/shared/services/templates/file-state/file-state.interface';

@Component({
    selector: 'app-clauses-tree-text',
    templateUrl: './clauses-tree-text.component.html',
    styleUrls: ['./clauses-tree-text.component.scss']
})
export class ClausesTreeTextComponent implements OnInit, OnDestroy {

    public clausesListViewed: ClauseDTO[] = [];
    public selectedClauses: ClauseDTO[] = [];
    public isLoadingFirstTime = true;
    public isSelectedClauses: boolean;
    public clausesNodeActions: NodeTreeActionType[] = [NodeTreeActionType.Selected, NodeTreeActionType.Checked];
    public rootActions: NodeTreeActionType[] = [NodeTreeActionType.Selected];
    public userInfo: UserInfoDTO;
    public clause: ClauseDTO = {
        id: '',
        name: '',
        html: ''
    };
    public currentNodeId: string;

    public actionBeforeCloseFinishHim = new BehaviorSubject<boolean>(false);
    public actionAfterCloseFinishHim = new Subject<boolean>();
    public isValidSubscription = new BehaviorSubject<boolean>(false);

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private loginService: AuthorizeService,
        private clauseService: ManagerClauseService,
        private fileStateService: IFileStateService) {

        this.loginService.getUserInfo()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(userInfo => {
            this.userInfo = userInfo;
        });
    }

    public ngOnInit(): void {
        if (this.actionBeforeCloseFinishHim) {
            this.actionBeforeCloseFinishHim.pipe(takeUntil(this.onDestroy$)).subscribe((doAction: boolean) => {
                this.getClauseContent(doAction);
            });
        }

        this.isLoadingFirstTime = false;
        this.loginService.forceRefreshUserInfo();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public selectedNodeAction(node: FileFlatNode) {
        if (!node || node.type !== NodeTreeNodeType.Clause) {
            return;
        }

        const clause = this.clausesListViewed.find(clauseInList => clauseInList.id === node.id);

        if (!!clause) {
            this.currentNodeId = node.id;
            this.clause = clause;
            return;
        }

        this.clauseService.get(node.id).subscribe((clauseGotten: ClauseDTO) => {
            this.currentNodeId = node.id;
            this.clause = clauseGotten;
            this.clausesListViewed.push(clauseGotten);
        });

    }

    public checkedNodeAction(node: FileFlatNode) {
        if (!node || node.type !== NodeTreeNodeType.Clause) {
            return;
        }

        if (node.isChecked) {
            this.addToChecked(node);
        } else {
            this.removeFromChecked(node);
        }
    }

    public getClauseStatus(): string {
        const statusCodeClause = this.fileStateService.getFileStatusCodeInClause(this.clause);
        return $localize`:@@ClausulaEstado:Cláusula en estado ` +
            this.fileStateService.getFileStatusMessage(statusCodeClause);
    }

    private removeFromChecked(node: FileFlatNode){
        const index = this.selectedClauses.findIndex((clause: ClauseDTO) => clause.id === node.id);
        const clauseUnchecked = this.selectedClauses.splice(index, 1);
        this.clause = clauseUnchecked[0];
        this.currentNodeId = node.id;
        this.buttonState();
    }

    private addToChecked(node: FileFlatNode) {
        const clauseChecked = this.clausesListViewed.find(clause => clause.id === node.id);

        if (!!clauseChecked) {
            this.updateClauseSelected(clauseChecked, node);
            return;
        }

        this.clauseService.get(node.id).subscribe(clause => {
            this.clausesListViewed.push(clause);
            this.updateClauseSelected(clause, node);
        });
    }

    private updateClauseSelected(clauseSelected: ClauseDTO, node: FileFlatNode) {
        this.clause = clauseSelected;
        this.selectedClauses.push(clauseSelected);
        this.currentNodeId = node.id;
        this.buttonState();
    }

    private getClauseContent(doAction: boolean) {
        if (!doAction) {
            return;
        }

        const observables = [];
        this.selectedClauses.forEach((clause: ClauseDTO) => {
            if (!clause.html || clause.html.trim() === '') {
                observables.push(
                    this.clauseService.get(clause.id).pipe(
                        tap((clauseWithContent: ClauseDTO) => {
                            clause.name = clauseWithContent.name;
                            clause.html = clauseWithContent.html;
                            clause.currentVersion = clauseWithContent.currentVersion;
                            clause.clauseVersions = clauseWithContent.clauseVersions;
                })));
            }
        });

        if (observables.length === 0) {
            this.actionAfterCloseFinishHim.next(true);
            return;
        }

        forkJoin(observables).subscribe(() => {
            this.actionAfterCloseFinishHim.next(true);
        });
    }

    private buttonState() {
        this.isValidSubscription.next(this.selectedClauses.length > 0);
    }
}
