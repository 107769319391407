import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) { }
    init(): void {
        const svgIcons = [
            { name: 'signature', url: '/assets/icons/signature.svg' },
            { name: 'download', url: '/assets/icons/arrow-down-to-dotted-line.svg' },
            { name: 'clone', url: '/assets/icons/clone.svg' },
            { name: 'selector', url: '/assets/icons/eye-dropper.svg' },
            { name: 'view-file', url: '/assets/icons/file-magnifying-glass.svg' },
            { name: 'file', url: '/assets/icons/file.svg' },
            { name: 'note-sticky', url: '/assets/icons/note-sticky.svg' },
            { name: 'versions', url: '/assets/icons/rectangle-history.svg' },
            { name: 'editable', url: '/assets/icons/edit.svg' },
            { name: 'squarelist', url: '/assets/icons/square-list.svg' },
            { name: 'drag-and-drop', url: '/assets/icons/drag-and-drop.svg' },
            { name: 'facebook', url: '/assets/icons/ico-facebook.svg' },
            { name: 'twitter', url: '/assets/icons/ico-twitter.svg' },
            { name: 'linkedin', url: '/assets/icons/ico-linkedin.svg' },
            { name: 'youtube', url: '/assets/icons/ico-youtube.svg' },
            { name: 'scale-balanced', url: '/assets/icons/scale-balanced.svg' },
            { name: 'hand', url: '/assets/icons/hand.svg' },
            { name: 'pipette', url: '/assets/icons/pipette.svg' },
            { name: 'file-excel', url: '/assets/icons/file-excel.svg' },
            { name: 'comment', url: '/assets/icons/comment.svg' }
        ];
        svgIcons.forEach(svgIcon => this.matIconRegistry.addSvgIcon(
            svgIcon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/es' + svgIcon.url)
        ));
    }

}
