import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class CkeditorStylesCMCService {
    public addStylesCMC() {
        CKEDITOR.stylesSet.add('default', [
            { name: $localize`:@@EstiloCMCTitulo:TÍTULO`, element: 'h2', attributes: { 'class': 'h2-title' } },
            { name: $localize`:@@EstiloCMCSubtitulo:SubTítulo`, element: 'h3', attributes: { 'class': 'h3-sub-title' } },
            { name: $localize`:@@EstiloCMCAbstract:Abstract`, element: 'div', attributes: { 'class': 'fAb' } },
            { name: $localize`:@@EstiloCMCDivision1:División nivel 1`, element: 'p', attributes: { 'class': 'd1' } },
            { name: $localize`:@@EstiloCMCDivision2:División nivel 2`, element: 'p', attributes: { 'class': 'd2' } },
            { name: $localize`:@@EstiloCMCDivision3:División nivel 3`, element: 'p', attributes: { 'class': 'd3' } },
            { name: $localize`:@@EstiloCMCDivision4:División nivel 4`, element: 'p', attributes: { 'class': 'd4' } },
        ]);
    }

}
