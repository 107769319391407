import { EditorDocumentParts } from './../models/editor-document-parts';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { CKEditor4 } from 'ckeditor4-angular';
import { environment } from 'src/environments/environment';
import { CkeditorFontsService } from '../services/ckeditor-fonts';
import { CkeditorFormsCheckBoxService } from '../services/ckeditor-forms-checkbox.service';
import { CkeditorFormsRadioService } from '../services/ckeditor-forms-radio.service';
import { CkeditorFormsSelectService } from '../services/ckeditor-forms-select.service';
import { CkeditorFormsTextAreaService } from '../services/ckeditor-forms-text-area.service';
import { CkeditorFormsTextFieldService } from '../services/ckeditor-forms-text-field.service';
import { CkeditorFormsUtilsService } from '../services/ckeditor-forms-utils.service';
import { PathLocationStrategy } from '@angular/common';

declare let CKEDITOR: any;

@Component({
    selector: 'app-wysiwyg-editor-basic',
    templateUrl: './wysiwyg-editor-basic.component.html'
})
export class WysiwygEditorBasicComponent implements OnDestroy, OnInit, OnChanges {

    @Input() dataEditor: string;
    @Input() initialHeight = 350;
    @Input() editorReadOnly: boolean;
    @Input() cssModalClassName: '';
    @Input() editorInModal = false;
    @Input() set customConfig(value: string) {
        this.isCmcConfig = value === 'cmcEditor';
    }

    @Output() changeContent = new EventEmitter<EditorDocumentParts>();
    @Output() editorReady = new EventEmitter<boolean>();

    private isCmcConfig = false;

    private editorInstance: any;
    private documentPartsForEditor = new EditorDocumentParts();

    constructor(private ckeditorFormsCheckBoxService: CkeditorFormsCheckBoxService,
                private ckeditorFormsRadioService: CkeditorFormsRadioService,
                private ckeditorFormsSelectService: CkeditorFormsSelectService,
                private ckeditorFormsTextAreaService: CkeditorFormsTextAreaService,
                private ckeditorFormsTextFieldService: CkeditorFormsTextFieldService,
                private ckeditorFormsUtilsService: CkeditorFormsUtilsService,
                private ckeditorFontsService: CkeditorFontsService,
                private pathLocationStrategy: PathLocationStrategy) {

        this.editorReadOnly = false;

    }

    ngOnInit(): void {
        this.addGenericPlugin();
        this.configureToolbars();
        this.setSkin();
        this.configureSize();
        this.addOpenLink();
        this.addIndentBlock();
        this.addbase64Image();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.editorReadOnly) {
            this.setReadOnly();
        }

        if (changes.dataEditor) {
            this.documentPartsForEditor.html = changes.dataEditor.currentValue;
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this.destroyEditor();
    }

    public setPrefixRoute(): string {
        const prefix = '/es';
        if (environment.apiBaseURL.indexOf('local') !== -1) {
            return '';
        }
        return prefix;
    }

    destroyEditor() {
        if (this.editorInstance) {

            if (!this.editorInstance.isDestroyed()) {
                this.editorInstance.destroy();
            }
        }
    }

    onEditorReady(event: CKEditor4.EventInfo) {
        this.editorInstance = event.editor;

        setTimeout(() => {
            this.changeTooltipFromButton();
        });

        this.setReadOnly();
        this.rBoxHideContent();
        this.resetUnsavedChanges();
        this.editorReady.emit(true);
    }

    sendDataChange(event) {
        this.sendDataChangeWithEditorInstance(event, this.editorInstance, this.changeContent, this.documentPartsForEditor);
    }

    sendDataChangeWithEditorInstance(event, editorInstance: any, changeContent: EventEmitter<EditorDocumentParts>,
                                     documentPartsForEditor: EditorDocumentParts) {
        if (editorInstance === undefined) {
            return;
        }

        const currentData = editorInstance.getData();
        documentPartsForEditor.html = currentData;
        changeContent.emit(documentPartsForEditor);
    }

    public updateDataInEditor() {
        if (this.editorInstance === undefined || this.editorInstance.textEditor === undefined) {
            return;
        }

        this.editorInstance.textEditor.setData(this.dataEditor);
        setTimeout(() => {
            this.editorInstance.checkDirty();
        });
    }

    setSkin() {
        CKEDITOR.config.skin = 'office2013';
    }

    addGenericPlugin() {
        CKEDITOR.config.extraPlugins = 'div,forms,copyformatting,find,selectall,justify,colorbutton,autogrow,';

        CKEDITOR.config.removePlugins = 'elementspath,resize';  // remove botton bar

        this.ckeditorFormsTextFieldService.configureTextField();
        this.ckeditorFormsCheckBoxService.configureCheckBox();
        this.ckeditorFormsRadioService.configureRadio();
        this.ckeditorFormsTextAreaService.configureTextArea();
        this.ckeditorFormsSelectService.configureSelect();
        this.ckeditorFontsService.configureFonts();
    }

    addIndentBlock() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/indentblock/';
        CKEDITOR.plugins.addExternal('indentblock', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('indentblock')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('indentblock,');
        }
    }

    addOpenLink() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/openlink/';
        CKEDITOR.plugins.addExternal('openlink', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('openlink')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('openlink,');
        }
    }

    addbase64Image() {

        const mydir = this.pathLocationStrategy.getBaseHref() + 'plugins/base64image/';
        CKEDITOR.plugins.addExternal('base64image', mydir, 'plugin.js');

        if (!(CKEDITOR.config.extraPlugins as string).includes('base64image')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('base64image,');
        }
    }

    configureToolbars() {
        CKEDITOR.config.customConfig = '';


        CKEDITOR.config.toolbar = [
            { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'CopyFormatting', 'RemoveFormat', '-', 'Undo', 'Redo'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
            { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
            {
                name: 'styles', items: environment.production ?
                    ['Font', 'FontSize'] :
                    ['Styles', 'Format', 'Font', 'FontSize']
            },
            { name: 'editing', items: ['SelectAll'/*, 'Scayt'*/] },
            { name: 'forms', items: ['TextField', 'Select', 'Checkbox', 'Radio'] },
            { name: 'tools', items: ['Maximize'] }
        ];

        if (!environment.production) {
            CKEDITOR.config.toolbar.push({ name: 'documents', items: ['Source'] });
        }

    }

    configureSize() {
        if (this.initialHeight !== undefined) {
            CKEDITOR.config.height = this.initialHeight;
            CKEDITOR.config.autoGrow_maxHeight = this.initialHeight;
            CKEDITOR.config.autoGrow_minHeight = this.initialHeight;
        }
        else {
            CKEDITOR.config.autoGrow_maxHeight = undefined;
            CKEDITOR.config.autoGrow_minHeight = undefined;
        }

        CKEDITOR.config.allowedContent = true;
        CKEDITOR.config.contentsCss = this.pathLocationStrategy.getBaseHref() + 'css/contractBox.css';
    }



    dataReady(event: CKEditor4.EventInfo) {
        const editorInstance = event.editor;

        if (editorInstance === undefined || editorInstance.document === undefined) {
            return;
        }
        this.ckeditorFormsUtilsService.manageAnchorLinks(editorInstance);
        if (this.editorReadOnly) {
            this.ckeditorFormsUtilsService.configureInputsAndSelectedAsReadOnly(editorInstance);
        } else {
            this.ckeditorFormsUtilsService
                .configureInputsAndSelectedEditables(editorInstance, this.sendDataChangeWithEditorInstance, this.changeContent,
                    this.documentPartsForEditor);
        }

        this.configureHelpNote(editorInstance);

        editorInstance.resetDirty();

    }

    public hasUnsavedChanges(): boolean {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        return this.editorInstance.checkDirty();
    }

    public resetUnsavedChanges(): boolean {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        this.editorInstance.resetDirty();
    }

    public resetUndoManager(): boolean {
        if (this.editorInstance === undefined || this.editorInstance === null) {
            return false;
        }

        this.editorInstance.resetUndo();
    }

    private changeTooltipFromButton() {
        const jquery = (window as any).$;

        jquery('.cke_button__textfield_label').text($localize`:@@InsertarCampoParaRellenar:Insertar campo para rellenar`);
        jquery('.cke_button__textfield').attr('title', $localize`:@@InsertarCampoParaRellenar:Insertar campo para rellenar`);

        jquery('.cke_button__checkbox_label').text($localize`:@@InsertarTextoOpcional:Insertar texto opcional`);
        jquery('.cke_button__checkbox').attr('title', $localize`:@@InsertarTextoOpcional:Insertar texto opcional`);

        jquery('.cke_button__radio_label').text($localize`:@@InsertarOpciones:Insertar párrafos alternativos`);
        jquery('.cke_button__radio').attr('title', $localize`:@@InsertarOpciones:Insertar párrafos alternativos`);

        jquery('.cke_button__select_label').text($localize`:@@InsertarDesplegable:Insertar desplegable`);
        jquery('.cke_button__select').attr('title', $localize`:@@InsertarDesplegable:Insertar desplegable`);

        jquery('.cke_button__copyformatting').attr('title', $localize`:@@CopiarFormato:Copiar formato`);

        this.resetUnsavedChanges();
    }

    private configureHelpNote(editorInstance: any) {
        const helpIcons = editorInstance.document.$.querySelectorAll('[class="nh"]');

        for (const icon of helpIcons) {
            const nextElement = icon.nextElementSibling;
            icon.setAttribute('title', nextElement.innerText);
        }
    }


    setReadOnly() {
        if (this.editorInstance) {
            this.editorInstance.setReadOnly(this.editorReadOnly);
        }
    }

    private rBoxHideContent() {
        if (this.isCmcConfig) {
            return;
        }
        const jquery = (window as any).$;
        const radioElements = this.editorInstance.document.$.querySelectorAll('[class="rBox"]');
        for (const radio of radioElements) {
            if (jquery(radio).find('.rhDesc').length) {
                jquery(radio).addClass('wHelp');
            }
        }
    }
}
