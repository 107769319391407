import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { branding } from 'src/config/configuration-urls';
import { UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PublicLinksService {
    public userInfo: BehaviorSubject<UserInfoDTO>;
    public userInfoSubscription: any;

    public urlSite: SafeResourceUrl;
    public brandName = branding.brandName;
    public brandNameCopyRight = branding.brandNameCopyRight;
    public brandUrl = branding.brandUrl;
    public brandContactClientMail = branding.brandContactClientMail;
    public mailToContactClientMail = 'mailto:' + branding.brandContactClientMail;
    public fundationUrl = branding.fundationUrl;
    public trainingUrl = branding.trainingUrl;
    public smartecaUrl = branding.smartecaUrl;
    public brandPhone = branding.brandPhone;
    public telPhone = 'tel:' + branding.brandPhone;
    public advertisingUrl = branding.advertisingUrl;
    public mainOfficeUrl = branding.mainOfficeUrl;
    public legalNoticeUrl = branding.legalNoticeUrl;
    public privacyNoticeUrl = branding.privacyNoticeUrl;
    public cookiesNoticeUrl = branding.cookiesNoticeUrl;
    public qualityEnviromentUrl = branding.qualityEnviromentUrl;
    public shopUrl = branding.shopUrl;
    public routeTraining = "/main/training";
    public legalAlerts = "/legal-alerts";
    public LLDUrl: string;

    public facebookUrl = branding.facebookUrl;
    public twitterUrl = branding.twitterUrl;
    public linkedinUrl = branding.linkedinUrl;
    public youtubeUrl = branding.youtubeUrl;

    public aenorUrl = branding.aenorUrl;
    public masfamiliaUrl = branding.masfamiliaUrl;
    public empleoygarantiajuvenilUrl = branding.empleoygarantiajuvenilUrl;

    public trainingCatalogUrl = branding.trainingCatalogUrl;

    public chatUrl = branding.chat;

    constructor(
        private loginService: AuthorizeService,
        private router: Router,
        private sanitizer: DomSanitizer,) {
        this.userInfo = this.loginService.getUserInfo();
        this.LLDUrl = this.getRouteLLD();
    }

    public isLandingPage() {
        const section = this.router.url;
        if (section.includes('/public/')) {
            return true;
        } else {
            return false;
        }
    }

    public getRouteLLD() {
        if (!this.userInfo.getValue()?.adminGuid) {
            return environment.laleydigitalLanding;
        }
        return environment.laleydigitalAllContent;
    }

    public sanitizeUrl(url: SafeResourceUrl): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${url}`);
    }

    public goToLink(link: SafeResourceUrl) {
        this.urlSite = this.sanitizeUrl(link);
        const data = this.sanitizer.sanitize(SecurityContext.URL, this.urlSite);
        window.open(data);
    }

    public goToHome() {
        this.router.navigate(['/main/home/index']);


    }
}
