import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class CkeditorPluginHelpNotesService {

    private readonly insertHelpNoteMessage = $localize`:@@InsertarNotaAyuda:Insertar nota de ayuda`;
    private currentHelpNote: any;

    public configureHelpNotesPlugin(): void {

        if (!(CKEDITOR.config.extraPlugins as string).includes('helpNotes')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('helpNotes,');
        }

        delete CKEDITOR.plugins.registered.helpNotes;

        const context = this;

        CKEDITOR.plugins.add('helpNotes', {
            icons: '',
            init: (editor: any) => {
                editor.addCommand('helpnotes', new CKEDITOR.dialogCommand('helpNotesDialog'));

                editor.ui.addButton('InsertHelpNotes', {
                    label: $localize`:@@InsertarNotaAyuda:Insertar nota de ayuda`,
                    command: 'helpnotes',
                    toolbar: 'forms',
                    icon: 'helpnotes'
                });


                editor.on('doubleclick', (evt) => {
                    const element = evt.data.element;

                    if (element && element.is('a') && element.hasClass('nh')) {
                        context.currentHelpNote = element;
                        evt.data.dialog = 'helpNotesDialog';
                    }
                });

                editor.on( 'selectionChange', (event: any) => {
                    context.setButtonState(editor);
                });

                editor.on( 'contentDom', () => context.addListenersToEditable(editor) );

                editor.on( 'keyup', () => {
                    context.setButtonState(editor);
                });

                CKEDITOR.dialog.add('helpNotesDialog', this.configureDialog());
            }
        });
    }

    private addListenersToEditable(editor: any) {
        let mouseupTimeout;
        const editable = editor.editable();
        const context = this;
        editable.attachListener( CKEDITOR.env.ie ? editable : editor.document.getDocumentElement(), 'mouseup', () => {
            mouseupTimeout = setTimeout( () => {
                context.setButtonState(editor);
            }, 0 );
            } );

        editor.on( 'destroy', () => {
            clearTimeout( mouseupTimeout );
        });
    }

    private setButtonState(editorInstance: any){
        editorInstance.ui.get('InsertHelpNotes')?.setState(this.getNexButtonState(editorInstance));
    }

    private getNexButtonState(editorInstance: any): any {
        const currentSelection = editorInstance.getSelection();
        const ranges = currentSelection.getRanges();
        const range = currentSelection && ranges[ 0 ];
        const inReadOnly = editorInstance.readOnly || ( range && range.checkReadOnly() );

        if ( inReadOnly) {
            return CKEDITOR.TRISTATE_DISABLED;
        }

        const selectionIsEmpty = currentSelection.getType() === CKEDITOR.SELECTION_NONE || ( ranges.length === 1 && ranges[ 0 ].collapsed );

        return selectionIsEmpty ? CKEDITOR.TRISTATE_OFF : CKEDITOR.TRISTATE_DISABLED;
    }

    private configureDialog(): void {
        if (CKEDITOR.dialog.exists('helpNotesDialog')) {
            return;
        }

        const context = this;

        CKEDITOR.dialog.add('helpNotesDialog', (editor: any) => {

            function createAnchorElement(editorInstance, helpNoteText, citeId) {
                const anchorElement = editorInstance.document.createElement('a');
                const anchorId = getRandomId();
                anchorElement.setAttribute('id', anchorId);
                anchorElement.setAttribute('class', 'nh');
                anchorElement.setAttribute('data-cke-saved-href', `javascript:vNt('${citeId}')`);
                anchorElement.setAttribute('title', helpNoteText);

                const dfnElement = createDfnElement(editorInstance);
                const dfnAnchorElement = dfnElement.appendTo(anchorElement);
                return dfnAnchorElement;
            }

            function createDfnElement(editorInstance) {
                const dfnElement = editorInstance.document.createElement('dfn');
                dfnElement.setAttribute('id', getRandomId());
                dfnElement.setText($localize`:@@VerNotaAyuda:Ver notas de ayuda`);
                return dfnElement;
            }

            function createCiteElement(editorInstance, helpNoteText, citeId) {
                const citeElement = editorInstance.document.createElement('cite');
                citeElement.setAttribute('class', 'nCl');
                citeElement.setAttribute('id', citeId);
                citeElement.setText(helpNoteText);
                return citeElement;
            }

            function getRandomNumber() {
                return Math.floor(Math.random() * (10000 - 100 + 1) + 100);
            }

            function getRandomId() {
                return 'I' + getRandomNumber();
            }

            function getCiteId() {
                return '_ID' + getRandomNumber();
            }

            function validatorCite( msg ) {
                return function() {
                    const value = this.getValue();
                    const pass = !!( CKEDITOR.dialog.validate.notEmpty(msg)(value) !== msg);

                    if ( !pass ) {
                        alert(msg); // jshint ignore:line
                        this.select();
                    }

                    return pass;
                };
            }

            return {
                title: $localize`:@@InsertarNotaAyuda:Insertar nota de ayuda`,
                minWidth: 350,
                minHeight: 100,
                getModel: (editorWithModel: any) => {
                    let element = editorWithModel.getSelection().getSelectedElement();

                    if (!element) {
                        element = context.currentHelpNote;
                    }

                    if (element && element.is('a') && element.hasClass('nh')) {
                        return element;
                    }

                    return null;
                },
                onShow() {
                    const element = this.getModel(this.getParentEditor());

                    if (element) {
                        this.setupContent(element);
                    }
                },
                onOk() {
                    const editorOnOk = this.getParentEditor();
                    const dialog = this;
                    const element = this.getModel(editorOnOk);
                    const isInsertMode = this.getMode(editorOnOk) === CKEDITOR.dialog.CREATION_MODE;

                    if (isInsertMode) {
                        const helpNoteText = dialog.getValueOf('info', 'helpNoteText');
                        const citeId = getCiteId();
                        const anchorElement = createAnchorElement(editorOnOk, helpNoteText, citeId);
                        editorOnOk.insertElement(anchorElement);

                        let previousElement = anchorElement.$.previousElementSibling;
                        if (previousElement && previousElement.tagName === 'INPUT') {
                            previousElement.placeholder = helpNoteText;
                        }

                        const citeElement = createCiteElement(editorOnOk, helpNoteText, citeId);
                        editorOnOk.insertElement(citeElement);
                    } else {
                        this.commitContent(element);
                    }

                    context.currentHelpNote = null;
                },
                onCancel() {
                    context.currentHelpNote = null;
                },
                contents: [{
                    id: 'info',
                    label: this.insertHelpNoteMessage,
                    title: this.insertHelpNoteMessage,
                    elements: [{
                        type: 'text',
                        id: 'helpNoteText',
                        label: $localize`:@@TextoNotaAyuda:Texto de la nota`,
                        required: true,
                        validate: validatorCite( $localize`:@@TextoNotaAyudaInserta:La nota de ayuda no puede estar vacía.`),

                        setup(element) {
                            this.enable();

                            let helpNoteText = '';

                            if (element.getAttribute('title')) {
                                helpNoteText = element.getAttribute('title');
                            } else {
                                helpNoteText = element.$.nextElementSibling.innerText;
                            }
                            this.setValue(helpNoteText);
                        },
                        commit(element) {
                            const newText = this.getValue();
                            element.setAttribute('title', newText);
                            element.$.nextElementSibling.innerText = newText;
                            let previousElement = element.$.previousElementSibling;

                            if (previousElement && previousElement.tagName === 'INPUT') {
                                previousElement.placeholder = newText;
                            }
                        }
                    }]
                }]
            };
        });
    }
}
